import { useMemo, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { debounce } from 'lodash';

import useFetchCustomersInfinite from 'modules/Bookkeeper/hooks/useFetchCustomersInfinite';
import Business from 'modules/Business/models/business';
import { selectBusiness } from 'modules/Business/Update/selectors';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import useTheme from 'modules/Theme/hooks/useTheme';
import { expandable } from 'modules/Theme/mixins';
import styled from 'modules/Theme/styled-components';
import { IconChange, IconArrow, IconSearch } from 'modules/Ui/Icons';
import { zIndex } from 'modules/Ui/zIndex';

import Box from '../Box';
import Button from '../Form/Button';
import Field from '../Form/Field';
import useGoBackToBookkeeper from '../Menu/hooks/useGoBackToBookkeeper';
import Text from '../Text';
import BookkeeperCustomers from './BookkeeperCustomers';
import messages from './messages';

const StyledExpandable = styled(Box)`
  ${expandable};
`;

const BookkeeperBussinesSearch: React.FC = () => {
  const { t } = useTranslations();
  const business = useSelector(selectBusiness);
  const handleGoBackToBookkeeper = useGoBackToBookkeeper();
  const theme = useTheme();
  const [searchTerm, setSearchTerm] = useState('');
  const name = business.fiscalName;
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
  } = useFetchCustomersInfinite({ searchTerm });

  const debouncedSearch = useCallback(
    debounce((nextValue) => setSearchTerm(nextValue), 500),
    []
  );

  const customers = useMemo(
    () =>
      data?.pages.reduce((acc, { items }) => {
        return [...acc, ...items];
      }, [] as Business[]) || [],
    [data]
  );

  return (
    <Box
      fadeIn
      maxWidth={{
        sm: theme.maxWidth.sm,
        md: theme.maxWidth.md,
        lg: theme.maxWidth.lg,
      }}
      position={{ _: 'sticky', sm: 'fixed' }}
      right={{ sm: '16px', xl: `calc((100vw - ${theme.maxWidth.xl}) / 2)` }}
      top={{ _: `${theme.mobileHeader}`, sm: '72px' }}
      width={{ _: '100vw', sm: 'initial' }}
      zIndex={zIndex.BOOKKEEPER_BUSSINES_SEARCH}
      data-testid="bookkeeper-business-search"
    >
      <StyledExpandable
        boxShadow={`0 4px 10px  ${theme.colors.shadow}`}
        tag="details"
        width={{ _: '100%', sm: '309px' }}
      >
        <Box
          backgroundColor="brand500"
          boxShadow={`0 4px 10px 0 ${theme.colors.shadow}`}
          height={{ _: '56px', sm: '64px' }}
          padding="0 24px 0 16px"
          tag="summary"
        >
          <Box alignItems="center" display="flex" tag="p" height="100%">
            <Box tag="span" flex="1" overflow="hidden">
              <Text
                color="gray0"
                display="block"
                fontSize={{ _: 16, sm: 14 }}
                fontWeight="500"
                hasEllipsis
                lineHeight={22}
                tag="span"
              >
                {name}
              </Text>
              <Text
                color="brand200"
                fontSize={12}
                hasEllipsis
                lineHeight={22}
                tag="span"
              >
                {t(messages.changeClient)}
              </Text>
            </Box>
            <IconArrow className="expandable__icon " color="gray0" />
          </Box>
        </Box>
        <Box
          backgroundColor="gray0"
          className="expandable__content"
          padding="14px 14px 0"
        >
          <Field
            data-testid="searchClient-other"
            flex={{ _: '1', sm: 'initial' }}
            icon={<IconSearch />}
            id="searchClient"
            label={t(messages.searchField)}
            marginBottom="16px"
            noDescription
            onChange={(event) => {
              debouncedSearch(event.target.value);
            }}
            placeholder={t(messages.searchFieldPlaceholder)}
            type="search"
          />
          <BookkeeperCustomers
            isLoading={status === 'loading'}
            fetchNext={() => fetchNextPage()}
            {...{ hasNextPage, isFetching, isFetchingNextPage, customers }}
          />
        </Box>
        <Box
          backgroundColor="gray0"
          boxShadow={`0 4px 10px 0 ${theme.colors.shadow}`}
          padding="0 0 16px"
          textAlign="center"
          fadeIn
        >
          <Box
            borderTop="1px solid"
            borderTopColor="primary200"
            display="flex"
            justifyContent="center"
            paddingTop="12px"
          >
            <Button
              data-testid="bookkeeper-admin-back"
              icon={<IconChange size={20} />}
              onClick={handleGoBackToBookkeeper}
            >
              {t(messages.goBack)}
            </Button>
          </Box>
        </Box>
      </StyledExpandable>
    </Box>
  );
};

export default BookkeeperBussinesSearch;
