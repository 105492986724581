import { ReactFragment } from 'react';
import { useSelector } from 'react-redux';

import { selectIsMobile } from 'modules/App/store/selectors';
import breakpoints, { min } from 'modules/Theme/breakpoints';
import useTheme from 'modules/Theme/hooks/useTheme';
import styled from 'modules/Theme/styled-components';
import { Box } from 'modules/Ui';

interface TagProps {
  children: ReactFragment;
  /* This parameters are the property values of css content-visibility prop  */
  loading?: 'size' | 'layout' | 'style' | 'paint' | 'auto' | 'initial';
  'data-testid'?: string;
}

const Wrapper = styled(Box)<{ loading: string }>`
  ${({ loading }) => `
    content-visibility: ${loading};
    @media ${min(breakpoints.md)} {
      content-visibility: visible;
    }
  `}
`;

const PanelList = (props: TagProps) => {
  const isMobile = useSelector(selectIsMobile);
  const theme = useTheme();
  const { children, loading = isMobile && 'auto' } = props;
  return (
    <Wrapper
      {...{ loading }}
      backgroundColor="gray0"
      borderTop={{ _: `1px solid ${theme.colors.primary100}`, sm: 'none' }}
      border={{ sm: `1px solid ${theme.colors.primary100}` }}
      data-testid={props['data-testid']}
      paddingBottom="40px"
      tag="section"
    >
      {children}
    </Wrapper>
  );
};

export default PanelList;
