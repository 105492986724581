import breakpoints, { min } from 'modules/Theme/breakpoints';
import { css } from 'modules/Theme/styled-components';
import { zIndex } from 'modules/Ui/zIndex';

type Props = {
  error?: string;
  isClearable?: boolean;
  isDisabled?: boolean;
  labelAlignLeft?: boolean;
  small?: boolean;
};

export const defaultStyles = css`
  --select-background-color: ${(props) => props.theme.colors.gray0};
  --select-border-color: ${(props) => props.theme.colors.primary200};
  --select-icon-color: ${(props) => props.theme.colors.brand500};
  --select-text-color: ${(props) => props.theme.colors.gray800};
`;

export const disabledStyles = css`
  --select-background-color: ${(props) => props.theme.colors.auxiliary};
  --select-border-color: ${(props) => props.theme.colors.gray200};
  --select-icon-color: ${(props) => props.theme.colors.gray400};
  --select-text-color: ${(props) => props.theme.colors.gray400};
  cursor: text;
`;

export const errorStyles = css`
  --select-border-color: ${(props) => props.theme.colors.error};
  --select-icon-color: ${(props) => props.theme.colors.error};
`;

export const clearableSelectStyle = css`
  color: var(--select-text-color);
  padding-right: 48px;
`;

export const hoverStyles = css`
  --select-border-color: ${(props) => props.theme.colors.brand500};
  cursor: pointer;
`;

export const reactSelectStyles = css<Props>`
  --select-height: ${({ small }) => (small ? '32px' : '44px')};

  @media ${min(breakpoints.sm)} {
    ${({ labelAlignLeft }) => labelAlignLeft && 'flex: 1;'}
  }

  .react-select {
    &__label {
      margin-right: 12px;
    }

    &__dropdown-indicator {
      svg {
        color: var(--select-icon-color);
      }
    }

    &__indicator {
      &:hover {
        color: ${(props) => props.theme.colors.gray200};
      }
    }

    &__option {
      font-size: ${(props) => props.theme.fontSizes[16]};
      line-height: ${(props) => props.theme.lineHeights[26]};
      padding: 8px 12px;

      &__text {
        padding-right: 32px;
      }

      &:hover,
      &:focus-within,
      &:focus {
        cursor: pointer;
        background-color: ${(props) => props.theme.colors.gray200};
        color: ${(props) => props.theme.colors.gray900};
      }

      &--is-selected {
        background-color: ${(props) => props.theme.colors.brand400};
        color: ${(props) => props.theme.colors.gray0};

        &:hover,
        &:focus-within,
        &:focus {
          background-color: ${(props) => props.theme.colors.brand400};
          color: ${(props) => props.theme.colors.gray0};
        }
      }
    }

    &__control {
      ${({ isDisabled }) => (isDisabled ? disabledStyles : defaultStyles)}
      ${({ error }) => error && errorStyles}
      background-color: var(--select-background-color);
      border-color: var(--select-border-color);
      border-radius: 0;
      box-shadow: none;
      cursor: pointer;
      font-size: ${(props) => props.theme.fontSizes[16]};
      height: var(--select-height);
      line-height: ${(props) => props.theme.fontSizes[16]};
      min-height: var(--select-height);
      padding-right: 8px;
      width: 100%;

      &:focus-within,
      &:hover {
        ${hoverStyles};
        border-color: var(--select-border-color);
      }

      &--is-disabled svg {
        color: ${(props) => props.theme.colors.gray400};
      }

      &--menu-is-open {
        box-shadow: none;
        cursor: pointer;

        .react-select__indicator {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }

    &__placeholder {
      color: ${(props) => props.theme.colors.gray400};
    }

    &__single-value {
      ${({ isClearable }) => isClearable && clearableSelectStyle}
      color: var(--select-text-color);
      padding-right: 24px;
    }

    &__placeholder,
    &__single-value {
      font-size: ${(props) => props.theme.fontSizes[16]};
      line-height: ${(props) => props.theme.lineHeights[26]};
      margin: 0;
      top: 53%;
      width: 100%;
    }

    &__menu {
      background-color: ${(props) => props.theme.colors.gray0};
      box-shadow: 0 2px 4px 0 ${(props) => props.theme.colors.shadow};
      border: 1px solid ${(props) => props.theme.colors.gray300};
      border-top: none;
      border-radius: 0;
      padding: 0;
      overflow: hidden;
      margin: 0;
      z-index: ${zIndex.SELECT};

      &-notice--no-options p {
        font-size: ${(props) => props.theme.fontSizes[15]};
      }

      &-list {
        padding: 0;
      }
    }

    &__value-container {
      padding: 0 ${(props) => props.theme.baseNumber} 0;
      height: var(--select-height);
      position: initial;
    }
  }
`;

export default reactSelectStyles;
