import useHasUserScope from 'modules/Auth/hooks/checkUsersPermissions';
import { ReceiptStatus } from 'modules/Documents/models/receiptStatus';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { getPaymentMethodTypeTranslation } from 'modules/Payments/helpers';
import {
  PaymentFilters,
  PaymentMethodEnum,
  PaymentSort,
  PaymentTable,
  PaymentTypeEnum,
} from 'modules/Payments/models';
import useTheme from 'modules/Theme/hooks/useTheme';
import { Link, PaymentStatus, Text } from 'modules/Ui';
import {
  DataTableAction,
  DataTableColumn,
} from 'modules/Ui/DataTable/DataTable.models';
import { IconEdit, IconExcel, IconTrash } from 'modules/Ui/Icons';
import { formatDate } from 'utils/dates';

import { listPaymentHeader, listPaymentActions } from '../messages';
import {
  useDeletePaymentsAction,
  useDownloadPaymentExcelAction,
  useEditPaymentAction,
} from './actions';

export const usePaymentListConfig = ({
  filters,
  sortBy,
}: {
  filters?: PaymentFilters;
  sortBy?: PaymentSort;
}) => {
  const { t, formatCurrency } = useTranslations();
  const theme = useTheme();
  const { isEditor } = useHasUserScope();

  const bulkActions: DataTableAction<PaymentTable>[] = [
    {
      name: t(listPaymentActions.edit),
      icon: <IconEdit />,
      shouldActionRender: isEditor,
      disableMultiple: true,
      onClick: useEditPaymentAction(),
    },
    {
      shouldActionRender: true,
      name: t(listPaymentActions.downloadExcel),
      abbrname: t(listPaymentActions.abbrDownloadExcel),
      icon: <IconExcel />,
      onClick: useDownloadPaymentExcelAction({
        filters,
        sortBy,
      }),
    },
    {
      name: t(listPaymentActions.delete),
      icon: <IconTrash />,
      shouldActionRender: isEditor,
      onClick: useDeletePaymentsAction(),
    },
  ];

  const columns: DataTableColumn<PaymentTable, keyof PaymentTable>[] = [
    {
      name: t(listPaymentHeader.operationDate),
      field: 'operationDate',
      textAlign: { _: 'right', sm: 'initial' },
      sortField: 'operationDate',
      value: ({ operationDate }) => formatDate(operationDate),
      width: { sm: '106px', md: '132px', lg: '96px' },
      className: 'operationDate',
    },
    {
      name: t(listPaymentHeader.account),
      field: 'accountingAccount.alias' as any,
      sortField: 'accountingAccount.alias' as any,
      value: ({ accountingAccount }) => {
        const alias = accountingAccount?.alias;
        return (
          <Text
            color={alias ? 'gray800' : 'gray600'}
            display="block"
            fontSize={14}
            fontStyle={alias ? undefined : 'italic'}
            hasEllipsis
            lineHeight={20}
            tag="span"
          >
            {alias ?? t(listPaymentHeader.noAccount)}
          </Text>
        );
      },
      width: '132px',
      minWidth: { md: '132px' },
      maxWidth: { md: '132px', lg: '200px' },
      className: 'account',
    },
    {
      name: t(listPaymentHeader.paymentMethod),
      field: 'method',
      sortField: 'method',
      minWidth: { md: '132px' },
      maxWidth: { md: '132px', lg: '200px' },
      width: '144px',
      value: ({ method }) => {
        const isNotConfirmed = method === PaymentMethodEnum.NOT_CONFIRMED;
        return (
          <Text
            fontStyle={isNotConfirmed ? 'italic' : 'normal'}
            fontSize={14}
            color={isNotConfirmed ? theme.colors.gray600 : theme.colors.gray800}
            lineHeight={22}
            hasEllipsis
          >
            {getPaymentMethodTypeTranslation(method, t)}
          </Text>
        );
      },
      className: 'method',
      disableSort: true,
    },
    {
      name: t(listPaymentHeader.contact),
      field: 'contact.fiscalName' as any,
      sortField: 'contact.fiscalName' as any,
      maxWidth: '299px',
      color: theme.colors.gray800,
      className: 'fiscalName',
      fontSize: `${theme.fontSizes[16]}`,
      lineHeight: `${theme.lineHeights[20]}`,
      value: ({ contact, documents }) => {
        const fiscalName = contact?.fiscalName;
        if (fiscalName) {
          return fiscalName;
        }

        return documents?.[0]?.receiptStatus !== ReceiptStatus.IS_RECEIPT
          ? t(listPaymentHeader.deletedContact)
          : t(listPaymentHeader.receipt);
      },
    },
    {
      name: t(listPaymentHeader.documents),
      field: 'documents',
      fontWeight: 600,
      maxWidth: '184px',
      value: ({ documents, type, description }) => {
        if (!documents?.length) {
          return (
            <Text
              color="gray600"
              display="block"
              fontSize={14}
              hasEllipsis
              lineHeight={20}
              fontWeight={100}
              tag="span"
            >
              {description}
            </Text>
          );
        }
        const document = documents[0];
        const url =
          type === PaymentTypeEnum.INCOME
            ? '/documents/issued/'
            : '/documents/expenses/';
        return (
          <Link
            fontWeight="400"
            borderBottom={`1px solid ${theme.colors.brand500}`}
            to={`${url}${document.id}`}
          >
            {document.identifier}
          </Link>
        );
      },
      className: 'documents',
      fontSize: `${theme.fontSizes[14]}`,
      lineHeight: `${theme.lineHeights[22]}`,
    },
    {
      name: t(listPaymentHeader.amount),
      field: 'amount',
      color: theme.colors.gray800,
      fontWeight: 600,
      value: ({ amount, type }) => {
        const adjustedAmount =
          type === PaymentTypeEnum.INCOME ? amount : -1 * amount;
        const formatted = formatCurrency(adjustedAmount);

        if (adjustedAmount < 0) {
          return (
            <Text fontSize={14} lineHeight={22} color={theme.colors.error}>
              {formatted}
            </Text>
          );
        }
        return `+${formatted}`;
      },
      width: { sm: '67px', lg: '120px' },
      className: 'amount',
      fontSize: `${theme.fontSizes[14]}`,
      lineHeight: `${theme.lineHeights[22]}`,
      textAlign: 'right',
      headerTextAlign: 'right',
    },
    {
      name: t(listPaymentHeader.paymentType),
      textAlign: 'right',
      headerTextAlign: 'right',
      field: 'status',
      sortField: 'status',
      value: ({ status }) => {
        return <PaymentStatus status={status} />;
      },
      width: { sm: '78px', md: '96px' },
      className: 'status',
      disableSort: true,
    },
  ];

  return {
    bulkActions,
    columns: columns.filter((xs) => !xs.hidden),
  };
};
