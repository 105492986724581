import { Tenants } from '../models';
import { useTenant } from './useTenant';

export const useTenantHasAccessToPlans = () => {
  const tenant = useTenant();
  return ![
    Tenants.EUSKALTEL,
    Tenants.MASMOVIL,
    Tenants.TELEFONICA,
    Tenants.FINETWORK,
    Tenants.TSDELSOL,
    Tenants.TSAPLIFISA,
  ].includes(tenant);
};
