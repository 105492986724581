import { ReactNode } from 'react';

import { Dictionary } from 'lodash';

import { Entity } from 'modules/App/models';
import { UserNotificationPaylodType } from 'modules/UserNotifications/models/UserNotificationPaylodType';

export enum NotificationType {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  INFO = 'INFO',
}

export interface Notification extends Entity {
  readonly ctaTranslationKey?: string;
  readonly hideIcon?: boolean;
  // message should be the translation key most of the times, but sometimes,
  // it has to be the translated value because it has embebed params
  readonly message: string | ReactNode;
  // this attr should be used in those cases where message is the translation value,
  // we need the message key to identify internally the notification and send the right analytic event
  readonly messageKey?: string;
  readonly onCtaClick?: (notification?: Notification) => void;
  readonly title?: string;
  readonly ttl?: number;
  readonly type: NotificationType;
  readonly haunter?: boolean;
  readonly icon?: JSX.Element;
  readonly messagePayload?: Dictionary<any>;
}

export interface BubbleNotification extends Entity {
  readonly message: string;
  readonly ttl?: number;
  readonly payload?: UserNotificationPaylodType;
}
