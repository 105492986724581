import axios, { AxiosResponse } from 'axios';

import defaultConfig from './defaultConfig';
import { AppConfig } from './models';

let request: AxiosResponse<AppConfig> | null = null;

export default async function getConfig() {
  try {
    if (!request) {
      request = await axios.get<AppConfig>('/config/config.json');
    }
    return { ...defaultConfig, ...request.data };
  } catch (e) {
    request = { data: defaultConfig } as any;
    return defaultConfig;
  }
}

export function getLoadedConfig() {
  return {
    ...defaultConfig,
    request: (request as AxiosResponse<AppConfig>).data,
  };
}
