import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Box } from 'modules/Ui';
import { IconArrow } from 'modules/Ui/Icons';

import ButtonNextPrev from './ButtonNextPrev';
import ButtonPage from './ButtonPage';
import calculateShownPages from './calculateShownPages';
import messages from './messages';
import Number from './Number';

interface PagesProps {
  activePage: number;
  id?: string;
  onChangePage(page: number): () => void;
  pages: number;
}

const Pages = (props: PagesProps) => {
  const { id, onChangePage, pages, activePage } = props;
  const shownPages = calculateShownPages(pages, activePage);
  const { t } = useTranslations();
  return (
    <Box tag="nav" role="navigation">
      <Box tag="ul" display="flex" alignItems="center">
        <Box tag="li" display="flex" alignItems="center">
          <ButtonNextPrev
            accessibleText={t(messages.prevPage)}
            isDisabled={activePage === 1}
            id={`${id}-prev-page`}
            onClick={onChangePage(activePage - 1)}
            {...{ activePage, pages }}
          >
            <IconArrow transform="rotate(90deg)" />
          </ButtonNextPrev>
        </Box>
        {shownPages.map((page) => (
          <li key={page}>
            {page < 0 ? (
              <Number aria-hidden="true" number="..." />
            ) : (
              <ButtonPage
                aria-current={page === activePage && 'page'}
                aria-label={
                  page === activePage
                    ? `${t({ ...messages.actualPage }, { page })}`
                    : `${t({ ...messages.goPage }, { page })}`
                }
                disabled={page === activePage}
                id={`page-${id || ''}-${page}`}
                data-testid={`page-${id || ''}-${page}`}
                onClick={onChangePage(page)}
              >
                <Number active={page === activePage} number={page} />
              </ButtonPage>
            )}
          </li>
        ))}
        <Box tag="li" display="flex" alignItems="center">
          <ButtonNextPrev
            accessibleText={t(messages.nextPage)}
            isDisabled={activePage === pages}
            id={`next-page-${id}`}
            onClick={onChangePage(activePage + 1)}
            {...{ activePage, pages }}
          >
            <IconArrow transform="rotate(-90deg)" />
          </ButtonNextPrev>
        </Box>
      </Box>
    </Box>
  );
};

export default Pages;
