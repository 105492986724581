import { createSelector } from 'reselect';

import { PLANS } from 'modules/Subscriptions/models';
import { ApplicationRootState } from 'types';

import {
  hasSubscription,
  isTrialEnded,
  isTrialNearToExpire,
  hasCancellationBeenRequested,
  subscriptionCancelledAt,
  getPlanId,
  getPlanName,
  isActive,
  isTrial,
  isPaused,
  mustShowReactivationBanner,
} from '../subscription.helpers';

const selectBusinessState = (state: ApplicationRootState) =>
  state.business.business;

export const getSubscription = (state: ApplicationRootState) =>
  state.subscriptions.subscription;

export const selectHasTrialEnded = createSelector(
  getSubscription,
  (subscription) => isTrialEnded(subscription)
);

export const selectHasSubscription = createSelector(
  getSubscription,
  (subscription) => hasSubscription(subscription)
);

export const selectIsTrialNearToExpire = createSelector(
  getSubscription,
  (subscription) => isActive(subscription) && isTrialNearToExpire(subscription)
);

export const selectIsMySubsciptionActive = createSelector(
  getSubscription,
  (subscription) => isActive(subscription)
);

export const selectIsMySubsciptionPaused = createSelector(
  getSubscription,
  (subscription) => isPaused(subscription)
);

export const selectIsMySubsciptionTrial = createSelector(
  getSubscription,
  (subscription) => isTrial(subscription)
);

export const selectIsMySubsciptionNearToBeCancelled = createSelector(
  getSubscription,
  (subscription) => hasCancellationBeenRequested(subscription)
);

export const selectIfMustShowReactivationBanner = createSelector(
  getSubscription,
  (subscription) => mustShowReactivationBanner(subscription)
);

export const selectSubscriptionCancellationDate = createSelector(
  getSubscription,
  (subscription) => subscriptionCancelledAt(subscription)
);

export const selectHasBasicPlan = createSelector(
  getSubscription,
  (subscription) => {
    const planId = getPlanId(subscription);
    return PLANS.BASIC.includes(planId);
  }
);

export const selectHasProPlan = createSelector(
  getSubscription,
  (subscription) => {
    const planId = getPlanId(subscription);
    return PLANS.PRO.includes(planId);
  }
);

export const selectHasUnlimitedPlan = createSelector(
  getSubscription,
  (subscription) => {
    const planId = getPlanId(subscription);
    return PLANS.UNLIMITED.includes(planId);
  }
);

export const selectHasExternalPlan = createSelector(
  getSubscription,
  (subscription) => {
    const planId = getPlanId(subscription);
    return PLANS.EXTERNAL.includes(planId);
  }
);

export const selectSubscriptionName = createSelector(
  getSubscription,
  (subscription) => (isActive(subscription) ? getPlanName(subscription) : '')
);

export const selectPlanType = createSelector(
  getSubscription,
  (subscription) => {
    const planId = getPlanId(subscription);
    if (PLANS.PRO.includes(planId)) {
      return 'pro';
    }
    if (PLANS.BASIC.includes(planId)) {
      return 'basic';
    }
    return 'unlimited';
  }
);

export const shouldRenderSubscription = createSelector(
  getSubscription,
  selectBusinessState,
  (subscription) => hasSubscription(subscription)
);
