import { History } from 'history';
import { stringify } from 'qs';

import { ContactFilters } from 'modules/Contacts/models';
import { DocumentFilters } from 'modules/Documents/models/document';
import { CurrentTabType } from 'modules/Filters/types';
import { PaymentFilters } from 'modules/Payments';

export function useSaveQueryParamsFilters(history: History) {
  return (
    type: CurrentTabType | null,
    props: { event?: DocumentFilters | ContactFilters | PaymentFilters }
  ) => {
    const { event } = props;
    const documentKind = type && {
      documentKind: type,
    };
    const searchParams = {
      ...documentKind,
      ...event,
    };
    history.push({
      search: stringify(searchParams),
    });
  };
}
