import { getAuthData } from 'modules/Auth/request-auth-data';

import { Tenants } from '../models';

export const useTenant = () => {
  let { tenant } = getAuthData();
  tenant = Object.values(Tenants).find((enumValue) =>
    tenant?.startsWith(enumValue)
  );
  return tenant as Tenants;
};
