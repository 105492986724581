export enum SubscriptionPlans {
  TRIAL = 'trial',
  PRO = 'pro',
}

export const PAYMENT_PERIODS = {
  MONTHLY: 'MONTHLY',
  YEARLY: 'YEARLY',
};

export const DAYS_TO_EXPIRE = 15;

export const DURATION = {
  MONTHLY: 'M',
  YEARLY: 'Y',
  DAILY: 'D',
};

export const PLANS = {
  BASIC: ['plan_basic_monthly', 'plan_basic_yearly'],
  PRO: ['plan_pro_monthly', 'plan_pro_yearly'],
  UNLIMITED: [
    'plan_unlimited_monthly',
    'plan_unlimited_yearly',
    'plan_unlimited_external',
  ],
  EXTERNAL: ['plan_unlimited_external'],
};

export const COUPON_DATE_FORMAT = 'dd/MM/yy';

export enum Tenants {
  EUSKALTEL = 'Euskaltel',
  MASMOVIL = 'MasMovil',
  FINETWORK = 'Finetwork',
  TELEFONICA = 'Telefonica',
  TSDELSOL = 'TS_DelSol',
  TSAPLIFISA = 'TS_Aplifisa',
}
