import { FC } from 'react';
import { Hidden } from 'react-grid-system';

import { space, layout } from 'styled-system';

import { AnalyticsEvent } from 'modules/Analytics';
import analyticsService from 'modules/Analytics/services/analyticsService';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import useTheme from 'modules/Theme/hooks/useTheme';
import styled from 'modules/Theme/styled-components';
import { Box, ButtonAction, Text } from 'modules/Ui';
import { IconDownload } from 'modules/Ui/Icons';

import messages from '../messages';

const StyledBanner = styled(Box)`
  ${space}
  ${layout}
`;

const StyledButtonAction = styled(ButtonAction)`
  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      border-color: ${({ theme }) => theme.colors.confirmationAccesible};
    }
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.confirmationAccesible};
  }
`;

const BannerWelcome: FC = () => {
  const { t } = useTranslations();
  const theme = useTheme();
  const ctaLink = '/assets/myBookkeeper/Home/manual-gestores.pdf';
  return (
    <StyledBanner
      backgroundColor="confirmationBackground"
      marginBottom={{ _: '16px', sm: '0' }}
      padding={{ _: '12px 16px', sm: '16px 24px' }}
    >
      <Box
        alignItems={{ sm: 'center' }}
        display={{ sm: 'grid' }}
        gap={{ sm: '32px' }}
        gridTemplateColumns={{ sm: '1fr 248px' }}
        margin={{ sm: '0 auto' }}
        maxWidth={{ md: theme.maxWidth.md, lg: theme.maxWidth.lg }}
      >
        <Box>
          <Hidden xs>
            <Text
              color="primary500"
              fontWeight={600}
              lineHeight={19}
              marginBottom="6px"
            >
              {t(messages.title)}
            </Text>
          </Hidden>
          <Text
            fontSize={14}
            lineHeight={18}
            marginBottom={{ _: '8px', sm: '0' }}
          >
            {t(messages.subtitle)}
          </Text>
        </Box>
        <StyledButtonAction
          backgroundColor="gray0"
          icon={<IconDownload color={theme.colors.confirmationAccesible} />}
          text={t(messages.cta)}
          textProps={{
            color: 'gray800',
          }}
          to={ctaLink}
          height="48px"
          type="application/pdf"
          isExternal
          download="manual-gestores.pdf"
          onClick={() => {
            analyticsService.track(AnalyticsEvent.BOOKKEEPER_GUIDE_DOWNLOAD);
          }}
        />
      </Box>
    </StyledBanner>
  );
};

export default BannerWelcome;
