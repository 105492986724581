import { createContext, FC, useContext } from 'react';
import { QueryObserverResult, useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import {
  MutationFunction,
  RefetchOptions,
  RefetchQueryFilters,
} from 'react-query/types/core/types';

import { NOTIFICATIONS_LIST_COUNTER } from 'modules/App/queries/queries';
import { useAutosaveNotifications } from 'modules/Forms/hooks/useAutosaveNotifications';
import { ModalTypes } from 'modules/Modals/constants';
import { selectProps as selectModalProps } from 'modules/Modals/selectors';
import { USER_NOTIFICATIONS_POLLING_REFRESH_TIME } from 'modules/UserNotifications/constants';
import { fetchNotificationsCounter } from 'modules/UserNotifications/List/services/fetchNotificationsCounter';
import updateNotification from 'modules/UserNotifications/List/services/updateNotification';
import { UserNotification } from 'modules/UserNotifications/models/models';
import { useBlockingMutation } from 'utils/useBlockingMutation';

import { selectCurrentBusiness } from '../../../Business/Current/selectors';
import { receiveServerNotification } from '../../actions';

const ServerNotificationsContext = createContext<
  ServerNotificationsProviderValues | undefined
>(undefined);

interface ServerNotificationsProviderValues {
  data?: {
    unread: number;
    notifications: UserNotification[];
  };
  refetch: (options?: RefetchOptions & RefetchQueryFilters) => Promise<
    QueryObserverResult<
      {
        unread: number;
        notifications: UserNotification[];
      },
      unknown
    >
  >;
  isLoading: boolean;
  dataUpdatedAt: number;
  mutateAsync: MutationFunction<void, { id: string; read: boolean }>;
}

interface ServerNotificationsProviderProps {}

export const ServerNotificationsProvider: FC<
  ServerNotificationsProviderProps
> = (props) => {
  const { children } = props;

  const dispatch = useDispatch();
  const business = useSelector(selectCurrentBusiness);
  const { type } = useSelector(selectModalProps);
  const autosaveNotifications = useAutosaveNotifications();

  const query = useQuery(
    [NOTIFICATIONS_LIST_COUNTER, business.id],
    business.id
      ? fetchNotificationsCounter
      : () => {
          return {
            notifications: [] as UserNotification[],
            unread: 0,
          };
        },
    {
      refetchInterval: USER_NOTIFICATIONS_POLLING_REFRESH_TIME,
      enabled: true,
      onSuccess: (data) => {
        data.notifications.forEach((notification) => {
          if (type !== ModalTypes.MULTIPLE_ZIPS_DOWNLOAD) {
            dispatch(receiveServerNotification(notification));
          }
        });
      },
    }
  );

  const { mutateAsync } = useBlockingMutation(updateNotification, {
    onSuccess: () => {
      query.refetch();
    },
  });

  const { data, dataUpdatedAt, refetch, isLoading } = query;

  const dataWithAutosaveNotifications = {
    ...data,
    notifications: [...autosaveNotifications, ...(data?.notifications || [])],
    unread: (data?.unread || 0) + autosaveNotifications.length,
  };

  const value = {
    mutateAsync,
    data: dataWithAutosaveNotifications,
    dataUpdatedAt,
    refetch,
    isLoading,
  };
  return (
    <ServerNotificationsContext.Provider value={value}>
      {children}
    </ServerNotificationsContext.Provider>
  );
};

export const useServerNotifications = () => {
  const context = useContext(ServerNotificationsContext);
  if (context === undefined) {
    throw new Error(
      'useServerNotifications must be used within a ServerNotificationsProvider'
    );
  }
  return context;
};
