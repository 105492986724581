// "I will not condone a course of action that will lead us to war" -Queen Amidala
export const zIndex = {
  SElECTED_ACTIONS: 5,
  MENU_MOBILE: 10,
  SUBSCRIPTION_PICTURES: 10,
  DAYPICKER: 10,
  SPINNER_SVG: 10,
  SUBSCRIPTION_PLAN: 15,
  SELECT: 20,
  SPINNER: 25,
  BANNER_BAI: 24,
  BOOKKEEPER_BUSSINES_SEARCH: 25,
  CHAT: 31,
  MENU_BACKDROP: 50,
  MENU_MOBILE_OPEN: 60,
  SUBMENU_MOBILE_OPEN: 70,
  MOBILE_VIEWER_PREVIEW: 70,
  HEADER: 81,
  NOTIFICATIONS: 82,
  ARROWBOX: 85,
  NOTIFICATION_ALERT: 85,
  MODALS: 90,
};
