import { forwardRef, Ref } from 'react';

import {
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
} from 'styled-system';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import theme from 'modules/Theme';
import { slidingCenterUnderline } from 'modules/Theme/mixins';
import styled from 'modules/Theme/styled-components';
import { Text, Box, PillBeta } from 'modules/Ui';
import HtmlButton from 'modules/Ui/Html/Button';

import { messages } from './messages';

interface Props
  extends React.ComponentPropsWithoutRef<'button'>,
    LayoutProps,
    SpaceProps,
    PositionProps,
    FlexboxProps {
  $active?: boolean;
  selected?: boolean;
  isBeta?: boolean;
  label?: React.ReactNode;
  notification?: number;
  onClick?: (value: any) => void;
  /**
   * You can provide your own value. Otherwise, we fallback to the child position index.
   */
  value?: any;
  onChange?: (event: any, value?: any) => void;
}

const StyledButton = styled(HtmlButton)<Props>`
  ${flexbox}
  ${layout}
  ${position}
  ${space}
  ${slidingCenterUnderline(theme.colors.accent400)}
  box-sizing: border-box;

  @media (hover: hover) {
    &:hover {
      color: ${(props) => props.theme.colors.gray800};
      cursor: ${({ $active }) => $active && 'text'};
    }
  }

  &:focus {
    color: ${(props) => props.theme.colors.gray800};
  }

  span {
    &::before {
      display: block;
      content: attr(data-title-for-hover);
      font-weight: bold;
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }
  }
`;

const Tab = forwardRef((props: Props, ref: Ref<HTMLButtonElement>) => {
  const {
    $active,
    isBeta,
    label,
    notification,
    onChange,
    onClick,
    selected,
    value,
    ...other
  } = props;
  const handleClick = (event: any) => {
    if (!selected && onChange) {
      onChange(event, value);
    }
    if (onClick) {
      onClick(event);
    }
  };
  const { t } = useTranslations();
  const textIsBeta = isBeta ? `, ${t(messages.beta)}` : '';
  const textNotification = notification
    ? ` ${notification} ${t(messages.notifications)}`
    : '';

  return (
    <Box
      alignItems={isBeta && 'center'}
      display={isBeta && 'flex'}
      marginRight="14px"
      tag="li"
    >
      <StyledButton
        $active={!!selected}
        alignItems={notification ? 'center' : undefined}
        aria-label={`${label}${textNotification}${textIsBeta}`}
        aria-controls={`tabpanel-${value}`}
        aria-selected={selected}
        display={notification ? 'flex' : undefined}
        height="50px"
        id={`tab-${value}`}
        onClick={handleClick}
        padding={{ _: '16px 8px 13px', sm: '16px 8px 13px' }}
        position="relative"
        ref={ref}
        role="tab"
        selected={!!selected}
        type="button"
        data-testid={`settings-${value}-page`}
        {...other}
      >
        <Text
          data-title-for-hover={label}
          color={selected ? 'gray800' : 'primary300'}
          className="tab--label"
          display={!notification ? 'block' : undefined}
          fontSize={14}
          fontWeight={selected ? '600' : undefined}
          hasEllipsis
          lineHeight={18}
          tag="span"
          {...{ selected }}
        >
          {label}
        </Text>
        {notification ? (
          <Text
            backgroundColor="stateNewBg"
            borderRadius="100px"
            color="stateNew"
            fontSize={12}
            lineHeight={16}
            marginLeft="8px"
            padding="0 6px"
            tag="span"
          >
            {notification}
          </Text>
        ) : null}
      </StyledButton>
      {isBeta && (
        <Box marginRight={{ _: '8px', sm: '0' }}>
          <PillBeta />
        </Box>
      )}
    </Box>
  );
});

export default Tab;
