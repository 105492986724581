import { ReactNode } from 'react';

import { space, SpaceProps, typography, TypographyProps } from 'styled-system';

import useTheme from 'modules/Theme/hooks/useTheme';
import accessibleHidden from 'modules/Theme/mixins/accessibleHidden';
import styled, { css } from 'modules/Theme/styled-components';

import IconInfo from '../../../Icons/IconInfo';
import Text from '../../../Text';
import Tooltip from '../../../Tooltip';

interface LabelTextProps extends SpaceProps, TypographyProps {
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  disabledWithAsterisk?: boolean;
  hidden?: boolean;
  id?: string;
  info?: ReactNode;
  infoDelay?: number;
  infoPlace?: 'top' | 'right' | 'bottom' | 'left';
  required?: boolean;
  requiredWithoutAsterisk?: boolean;
}

const infoStyles = css`
  align-items: center;
  display: flex;
  margin-bottom: 2px;
  position: relative;

`;

const StyledText = styled(Text)<LabelTextProps>`
  ${({ hidden }) => hidden && accessibleHidden}
  ${({ info }) => info && infoStyles}
  ${({ info, textAlign }) =>
    info && textAlign === 'right' && `justify-content: flex-end;`}
  ${space}
  ${typography}
`;

const shouldShowRequiredAsterisk = (props: LabelTextProps): boolean => {
  const { disabled, required, requiredWithoutAsterisk, disabledWithAsterisk } =
    props;
  if (!required) {
    return false;
  }
  if (disabled) {
    return !!disabledWithAsterisk;
  }
  return !requiredWithoutAsterisk;
};

const LabelText = (props: LabelTextProps) => {
  const theme = useTheme();
  const {
    children,
    className,
    disabled,
    disabledWithAsterisk,
    hidden,
    id,
    info,
    infoDelay,
    infoPlace,
    required,
    requiredWithoutAsterisk,
    textAlign,
    ...rest
  } = props;
  return (
    <StyledText
      {...{ textAlign, hidden, info, id, ...props }}
      className={className}
      color={hidden ? undefined : theme.colors.gray800}
      display="block"
      fontSize={14}
      hide={hidden}
      lineHeight={16}
      marginBottom={hidden && info ? undefined : '2px'}
      marginLeft="2px"
      minHeight="17px"
      tag="span"
      {...rest}
    >
      {children}
      {shouldShowRequiredAsterisk(props) && (
        <Text
          color="error"
          fontSize={14}
          lineHeight="normal"
          marginLeft="4px"
          tag="span"
        >
          *
        </Text>
      )}
      {info && (
        <Tooltip
          delayHide={infoDelay}
          id={id}
          tooltipButton={<IconInfo color="brand500" size={16} />}
          tooltipContent={info}
          place={infoPlace}
        />
      )}
    </StyledText>
  );
};

export default LabelText;
