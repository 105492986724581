import { ReactNode } from 'react';
import { Visible } from 'react-grid-system';

import { flexbox, FlexboxProps, space, SpaceProps } from 'styled-system';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import styled, { css } from 'modules/Theme/styled-components';
import Box from 'modules/Ui/Box';
import Img from 'modules/Ui/Img';
import Text from 'modules/Ui/Text';

import messages from './messages';

interface BannerDiscountProps extends FlexboxProps, SpaceProps {
  image: ReactNode;
  imageHeight?: number | string;
  imageWidth?: number;
  page?: 'home' | 'clientList';
}

export const homeStyles = css`
  --bannerDiscount--titleColor: ${(props) => props.theme.colors.gray800};
  --bannerDiscount--subtitleColor: ${(props) => props.theme.colors.gray600};
  --bannerDiscount--pillBgcolor: ${(props) =>
    props.theme.colors.warningBackground};
  --bannerDiscount--pillColor: ${(props) => props.theme.colors.gray800};
`;

export const clientListStyles = css`
  --bannerDiscount--titleColor: ${(props) => props.theme.colors.primary400};
  --bannerDiscount--subtitleColor: ${(props) => props.theme.colors.primary400};
  --bannerDiscount--pillBgcolor: ${(props) => props.theme.colors.brand500};
  --bannerDiscount--pillColor: ${(props) => props.theme.colors.gray0};
`;

export const StyledBanner = styled(Box)<BannerDiscountProps>`
  ${({ page }) => page === 'home' && homeStyles}
  ${({ page }) => page === 'clientList' && clientListStyles}
  ${flexbox}
  ${space}
`;

const DiscountContentBanner: React.FC<BannerDiscountProps> = (props) => {
  const {
    page = 'clientList',
    image,
    imageWidth,
    imageHeight,
    ...rest
  } = props;
  const { t } = useTranslations();
  return (
    <StyledBanner display="flex" {...{ page, ...rest }}>
      <Box marginRight={{ _: '8px', sm: '10px', md: '12px' }}>
        <Img
          alt=""
          ariaHidden={true}
          height={imageHeight}
          src={`/assets/myBookkeeper/Home/${image}@3x.png`}
          srcSet={`/assets/myBookkeeper/Home/${image}.png,
              /assets/myBookkeeper/Home/${image}@2x.png 2x,
              /assets/myBookkeeper/Home/${image}@3x.png 3x`}
          width={imageWidth}
        />
      </Box>
      <Box flex="1">
        {page === 'clientList' ? (
          <Text
            color="var(--bannerDiscount--titleColor)"
            fontSize={14}
            fontWeight="600"
            lineHeight={16}
            marginBottom="4px"
          >
            {t(messages.title)}
          </Text>
        ) : (
          <Visible lg xl xxl>
            <Text
              color="var(--bannerDiscount--titleColor)"
              fontSize={14}
              fontWeight="600"
              lineHeight={16}
              marginBottom="4px"
            >
              {t(messages.title)}
            </Text>
          </Visible>
        )}
        <Text
          color="var(--bannerDiscount--subtitleColor)"
          fontSize={11}
          lineHeight={14}
          marginBottom={{ _: '8px', md: '12px' }}
        >
          {t(messages.subtitle)}
        </Text>
        <Text
          alignItems="center"
          backgroundColor="var(--bannerDiscount--pillBgcolor)"
          boxSizing="border-box"
          color="var(--bannerDiscount-pillColor)"
          display="flex"
          fontSize={12}
          fontWeight={600}
          height={page === 'clientList' ? '22px' : '30px'}
          lineHeight={14}
          padding="1px 8px"
          width="100%"
          tag="span"
        >
          {t(messages.pill)}
        </Text>
      </Box>
    </StyledBanner>
  );
};

export default DiscountContentBanner;
