import { ReactNode } from 'react';

import useTheme from 'modules/Theme/hooks/useTheme';
import styled from 'modules/Theme/styled-components';

import Box from '../Box';
import Link from '../Link';

interface DropdownSubmenuActionProps {
  icon: ReactNode;
  text: string;
  url: string;
}

const StyledLink = styled(Link)`
  @media (hover: hover) {
    &:hover {
      color: ${(props) => props.theme.colors.brand500};
    }
  }
  &:focus {
    color: ${(props) => props.theme.colors.brand500};
  }
`;

export const DropdownSubmenuAction = ({
  text,
  icon,
  url,
}: DropdownSubmenuActionProps) => {
  const theme = useTheme();
  return (
    <Box tag="li">
      <StyledLink
        alignItems="center"
        color={theme.colors.gray800}
        columnGap="12px"
        display="grid"
        fontSize={theme.fontSizes[16]}
        gridTemplateColumns="24px 1fr"
        lineHeight={theme.lineHeights[20]}
        to={url}
      >
        {icon}
        {text}
      </StyledLink>
    </Box>
  );
};
