import { FC } from 'react';
import { Link } from 'react-router-dom';

import {
  flexbox,
  FlexboxProps,
  gridTemplateColumns,
  GridTemplateColumnsProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  ResponsiveValue,
  space,
  SpaceProps,
  system,
  Theme,
  TLengthStyledSystem,
  typography,
  TypographyProps,
} from 'styled-system';

import styled from 'modules/Theme/styled-components';
import { validProps } from 'modules/Ui/Html/A';
import { isValidProp } from 'modules/Ui/Html/cleanProps';
import { commonProps } from 'modules/Ui/Html/HtmlComponent';

import hoverAnimation, { HoverEffectProps } from './hoverAnimation';

export interface Props
  extends GridTemplateColumnsProps,
    TypographyProps,
    PositionProps,
    LayoutProps,
    SpaceProps,
    FlexboxProps {
  active?: boolean;
  boxSizing?: 'content-box' | 'border-box';
  color?:
    | string &
        ResponsiveValue<
          string | number | symbol,
          Required<Theme<TLengthStyledSystem>>
        >;
  gap?: {} | string;
  onClick?: () => void;
  testId?: string;
  text?: string;
  to?: string;
}

const StyledLink = styled(Link).withConfig({
  shouldForwardProp: (prop) => {
    return isValidProp([...commonProps, ...validProps, 'to'], prop);
  },
})<HoverEffectProps & Props>`
  ${({ color }) => color && `color: ${color};`}
  ${flexbox}
  ${gridTemplateColumns}
  ${hoverAnimation}
  ${layout}
  ${position}
  ${space}
  ${typography}
  ${system({
    gap: {
      property: 'gap',
      transform: (value) => `${value}`,
    },
    boxSizing: {
      property: 'boxSizing',
      scale: 'boxSizings',
    },
  })}
`;

const StyledButton = styled.button<Props>`
  align-items: center;
  display: flex;
  ${hoverAnimation}
`;

export const ActionLink: FC<Props> = ({
  active,
  children,
  color,
  gap,
  onClick,
  testId,
  to,
  ...rest
}) => {
  if (to) {
    return (
      <StyledLink
        $active={active}
        aria-current={active ? 'page' : undefined}
        color={color}
        data-testid={testId}
        gap={gap}
        to={to}
        {...rest}
      >
        {children}
      </StyledLink>
    );
  }
  return <StyledButton onClick={onClick}>{children}</StyledButton>;
};
