import { SagaIterator } from 'redux-saga';
import { delay, put, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import {
  UserNotificationTypes,
  DownloadZipPayload,
} from '../UserNotifications/models/UserNotificationPaylodType';
import * as actions from './actions';
import messages from './components/BubbleNotification/messages';
import ActionTypes from './constants';

export function* addNotificationSaga(
  action: ActionType<typeof actions.createNotification>
): SagaIterator {
  const { ttl, id } = action.payload;
  if (ttl) {
    yield delay(ttl);
    yield put(actions.removeNotification(id));
  }
}
export function* addBubbleNotificationSaga(
  action: ActionType<typeof actions.createBubbleNotification>
): SagaIterator {
  const { ttl, id } = action.payload;
  if (ttl) {
    yield delay(ttl);
    yield put(actions.removeBubbleNotification(id));
  }
}

export function* receiveServerNotificationSaga(
  action: ActionType<typeof actions.receiveServerNotification>
): SagaIterator {
  const { type } = action.payload;
  if (
    [
      UserNotificationTypes.userDownloadZip,
      UserNotificationTypes.bookkeeperDownloadZip,
    ].includes(type)
  ) {
    if (!(action.payload.payload as DownloadZipPayload).downloaded) {
      yield put(
        actions.createBubbleNotification({
          message: action.payload.bodyKey ?? messages.zipDownloadReady.id,
          payload: action.payload.payload,
          ttl: 6 * 1000,
        })
      );
    }
  }

  yield delay(100);
}

export default function* rootSaga() {
  yield takeEvery(
    ActionTypes.RECEIVE_SERVER_NOTIFICATION,
    receiveServerNotificationSaga
  );
  yield takeEvery(ActionTypes.ADD_NOTIFICATION, addNotificationSaga);
  yield takeEvery(
    ActionTypes.ADD_BUBBLE_NOTIFICATION,
    addBubbleNotificationSaga
  );
}
