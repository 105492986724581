import { FC } from 'react';
import { Hidden } from 'react-grid-system';

import {
  GridProps,
  layout,
  LayoutProps,
  typography,
  TypographyProps,
} from 'styled-system';

import { ellipsis } from 'modules/Theme/mixins';
import styled from 'modules/Theme/styled-components';

import Box from '../Box/Box';
import Text from '../Text/Text';

interface Props extends TypographyProps, LayoutProps, GridProps {
  description?: string | number | Date;
  descriptionColor?: string;
  descriptionEllipsis?: boolean;
  descriptionFontSize?: string;
  descriptionFontWeight?: string;
  hiddenTitleOnMobile?: boolean;
  tag?: string;
  title: string;
  id?: string;
}

const StyledDescription = styled(Text)``;
const StyledWrapper = styled(Box)`
  ${layout}
  ${({ descriptionEllipsis }) => descriptionEllipsis && `${ellipsis}`}

  ${StyledDescription} {
    ${typography}
    ${({ descriptionEllipsis }) => descriptionEllipsis && `${ellipsis}`}
  }
`;

export const ResumeItem: FC<Props> = ({
  description,
  descriptionColor,
  descriptionEllipsis = true,
  descriptionFontSize,
  descriptionFontWeight,
  hiddenTitleOnMobile = false,
  tag,
  textAlign,
  title,
  id,
  ...rest
}) => {
  const getTitle = () => {
    const titleComponent = (
      <Text
        color="primary300"
        display="block"
        fontSize={11}
        fontWeight="normal"
        lineHeight="normal"
        marginBottom="8px"
        tag="span"
        textAlign={textAlign}
        textTransform="uppercase"
        id={`${id}-title`}
        data-testid={`${id}-title`}
      >
        {title}
      </Text>
    );
    return hiddenTitleOnMobile ? (
      <Hidden xs>{titleComponent}</Hidden>
    ) : (
      titleComponent
    );
  };

  return (
    <StyledWrapper
      descriptionEllipsis={descriptionEllipsis}
      tag={tag || 'li'}
      {...rest}
    >
      {getTitle()}
      <StyledDescription
        color={descriptionColor || 'gray800'}
        display="block"
        fontSize={descriptionFontSize || 16}
        fontWeight={descriptionFontWeight || 'initial'}
        lineHeight="normal"
        tag="span"
        textAlign={textAlign}
        id={`${id}-description`}
        data-testid={`${id}-description`}
        {...rest}
      >
        {description}
      </StyledDescription>
    </StyledWrapper>
  );
};
