import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { UserPermissions } from 'modules/Auth/constants';
import useHasUserScope from 'modules/Auth/hooks/checkUsersPermissions';
import { useIsBookkeeperLoggedInClient } from 'modules/Bookkeeper/hooks/useIsBookkeeperUser';
import {
  selectCurrentBusiness,
  selectHasBookkeeperUsers,
} from 'modules/Business/Current/selectors';
import { useFeatureFlagLanguageSelector } from 'modules/Home/hooks/useFeatureFlagLanguageSelector';
import { useShowLanguageSelector } from 'modules/Home/hooks/useShowLanguageSelector';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { ROUTES as INTEGRATIONS } from 'modules/Integrations/routesMap';
import { SETTINGS_PATH } from 'modules/Settings/paths';
import { selectHasSubscription } from 'modules/Subscriptions/detail/selectors';
import { useTenantHasAccessToPlans } from 'modules/Subscriptions/hooks';
import { ROUTES as SUBSCRIPTIONS_ROUTES } from 'modules/Subscriptions/routesMap';
import styled from 'modules/Theme/styled-components';
import { Box, Button } from 'modules/Ui';
import { useShouldRenderSection } from 'modules/Ui/Menu/hooks/useShouldRenderSection';
import useClosesDropDownOnClickOutside from 'modules/Ui/useClosesExpandableOnClickOutside';
import useIsReader from 'modules/Users/hooks/useIsReader';
import { ROUTES as ROUTES_AUTH } from 'pages/auth/routesMap';
import { ROUTES } from 'pages/bookkeepers/routesMap';
import { ROUTES as BUSINESS_PATH } from 'pages/business/routesMap';
import isNativeApp from 'utils/native/isNativeApp';

import useGoBackToBookkeeper from '../../../hooks/useGoBackToBookkeeper';
import { menu } from '../../../messages';
import ExpandableBox from './ExpandableBox';
import ExpandableButton from './ExpandableButton';
import Link from './ExpandableLink';
import hoverAnimation from './hoverAnimation';

interface Props {
  id?: string;
  isBookkeeper?: boolean;
  open?: boolean;
}

const StyledButton = styled(Button)`
  ${hoverAnimation}
`;

const regexMap = {
  business: /^\/business/,
  users: /^\/users/,
  settings: /^\/settings/,
  subscriptions: /^\/subscriptions(?!\/plans)/,
  plans: /^\/subscriptions\/plans/,
  myBookkeeper: /^\/bookkeeper\/my-bookkeeper/,
  bookkeeperProfile: /^\/bookkeeper\/profile/,
  bookkeeperMyAccount: /^\/bookkeeper\/my-account/,
  integrations: /^\/integrations/,
};

const Expandable = ({ isBookkeeper, id, open: expanded }: Props) => {
  const isBookkeeperLoggedInClient = useIsBookkeeperLoggedInClient();
  const { open, toggleDropDown, ref } =
    useClosesDropDownOnClickOutside(expanded);
  const { t } = useTranslations();
  const hasSubscription = useSelector(selectHasSubscription);
  const location = useLocation();
  const { hasUserScope } = useHasUserScope();
  const isUserAdmin = hasUserScope(UserPermissions.SUBSCRIPTION_ADMIN);
  const { pathname } = location;
  const isBusinessActive = regexMap.business.test(pathname);
  const isUsersActive = regexMap.users.test(pathname);
  const isSettingsActive = regexMap.settings.test(pathname);
  const isSubscriptionsActive = regexMap.subscriptions.test(pathname);
  const isPlansActive = regexMap.plans.test(pathname);
  const isMyBookkeeperActive = regexMap.myBookkeeper.test(pathname);
  const isBookkeeperProfileActive = regexMap.bookkeeperProfile.test(pathname);
  const isBookkeeperMyAccountActive =
    regexMap.bookkeeperMyAccount.test(pathname);
  const ExpandableButtonIsActive =
    isBusinessActive ||
    isUsersActive ||
    isSettingsActive ||
    isSubscriptionsActive ||
    isPlansActive ||
    isMyBookkeeperActive;
  const isUserReader = useIsReader();
  const isIntegrationsActive = regexMap.integrations.test(pathname);

  const { shouldRenderPlansSection } = useShouldRenderSection();

  const showLanguage = useShowLanguageSelector();

  const handleGoBackToBookkeeper = useGoBackToBookkeeper();
  const showLanguageSelectorFF = useFeatureFlagLanguageSelector();
  const tenantHasPlans = useTenantHasAccessToPlans();
  const business = useSelector(selectCurrentBusiness);

  const hasBookkeeper = useSelector(selectHasBookkeeperUsers);

  return (
    <Box ref={ref} tag="li" {...{ expanded: open }} position="relative">
      <ExpandableButton
        {...{
          active: ExpandableButtonIsActive,
          id,
          isBookkeeper,
          open,
        }}
        onClick={toggleDropDown}
      />
      <Box onClick={() => toggleDropDown()}>
        <ExpandableBox {...{ id, open }}>
          {!isBookkeeper && (
            <>
              <Link
                active={isBusinessActive}
                text={t(menu.business)}
                to={BUSINESS_PATH.BUSINESS}
                data-testid="navbar-business"
              />
              <Link active={isUsersActive} text={t(menu.users)} to="/users" />
              {isUserAdmin && (
                <Link
                  active={isSettingsActive}
                  text={t(menu.settings)}
                  to={SETTINGS_PATH}
                  data-testid="navbar-settings"
                />
              )}
              <Link
                active={isSubscriptionsActive}
                hide={
                  isNativeApp() ||
                  !isUserAdmin ||
                  (!hasSubscription && tenantHasPlans)
                }
                text={t(menu.subscriptions)}
                to={SUBSCRIPTIONS_ROUTES.SUBSCRIPTIONS}
                data-testid="navbar-subscriptions"
              />
              <Link
                active={isPlansActive}
                hide={!shouldRenderPlansSection}
                text={t(menu.plans)}
                to={SUBSCRIPTIONS_ROUTES.SUBSCRIPTIONS_PLANS}
                data-testid="navbar-plans"
              />
              {!isUserReader && (
                <Link
                  active={isMyBookkeeperActive}
                  hide={isBookkeeperLoggedInClient || hasBookkeeper}
                  text={t(menu.myBookkeeper)}
                  to={ROUTES.MY_BOOKKEEPER}
                  data-testid="navbar-my-bookkeeper"
                />
              )}
              <Link
                active={isIntegrationsActive}
                data-testid="integrations"
                isBeta
                text={t(menu.integrations)}
                to={INTEGRATIONS.INTEGRATIONS}
              />
            </>
          )}
          {isBookkeeperLoggedInClient && (
            <Box tag="li" position="relative" padding="0 16px">
              <StyledButton
                color="primary400"
                fontSize={14}
                height="36px"
                lineHeight={22}
                onClick={handleGoBackToBookkeeper}
              >
                {t(menu.goBackToBookkeeper)}
              </StyledButton>
            </Box>
          )}
          {isBookkeeper && !isBookkeeperLoggedInClient && (
            <>
              <Link
                active={isBookkeeperProfileActive}
                text={t(menu.bookkeeperProfile)}
                to={ROUTES.BOOKKEEPER_PROFILE}
                data-testid="navbar-bookkeeper-profile"
              />
              <Link
                active={isBookkeeperMyAccountActive}
                text={t(menu.bookkeeperMyaccount)}
                to={ROUTES.BOOKKEEPER_MYACCOUNT}
                data-testid="navbar-bookkeeper-myAccount"
              />
            </>
          )}
          {showLanguageSelectorFF && (
            <Link
              hide={business?.isDummy}
              text={t(menu.languages)}
              onClick={showLanguage}
            />
          )}
          <Link text={t(menu.logout)} to={ROUTES_AUTH.LOGOUT} />
        </ExpandableBox>
      </Box>
    </Box>
  );
};

export default Expandable;
