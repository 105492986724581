import useTheme from 'modules/Theme/hooks/useTheme';
import { Box, PillBeta } from 'modules/Ui';

import { ActionLink } from './ActionLink';

export interface LinkProps {
  hide?: boolean;
  active?: boolean;
  text?: string;
  to?: string;
  onClick?: () => void;
  ['data-testid']?: string;
  isBeta?: boolean;
}

const SubmenuLink: React.FC<LinkProps> = ({
  hide,
  text,
  active,
  to,
  onClick,
  isBeta,
  ...props
}) => {
  const theme = useTheme();
  if (hide) {
    return null;
  }
  return (
    <Box overflow="hidden" position="relative" tag="li" zIndex="2">
      <ActionLink
        active={active}
        alignItems="center"
        boxSizing="border-box"
        color={theme.colors.primary400}
        display="flex"
        fontSize={14}
        fontWeight={active ? '600' : 'normal'}
        gap="4px"
        height="36px"
        lineHeight={16}
        onClick={onClick}
        padding="0 16px"
        position="relative"
        testId={props['data-testid']}
        to={to}
        zIndex={active ? 1 : undefined}
        width="100%"
      >
        {text}
        {isBeta && <PillBeta />}
      </ActionLink>
    </Box>
  );
};

export default SubmenuLink;
