import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Box, Text } from 'modules/Ui';
import { Option } from 'modules/Ui/Form/SelectCommon/types';

import messages from './messages';

interface Props {
  option: Option;
}

const OptionLabel: React.FC<Props> = ({ option }) => {
  const { t } = useTranslations();
  return (
    <Box display="flex" justifyContent="space-between">
      <Text
        className="react-select__option__text"
        tag="span"
        color="inherit"
        width="70%"
        hasEllipsis
      >
        {option?.label}
      </Text>
      {option.additionalData && (
        <Box
          color="inherit"
          width="30%"
          display="flex"
          justifyContent="flex-end"
        >
          {option?.additionalData.vatNumber ? (
            <Text
              className="react-select__option__text"
              color="inherit"
              tag="span"
            >
              {option.additionalData.vatNumber}
            </Text>
          ) : (
            <Text
              color="error"
              tag="span"
              className="react-select__option__text"
            >
              {t(messages.noVatNumber)}
            </Text>
          )}
        </Box>
      )}
    </Box>
  );
};

export default OptionLabel;
