import { Formik } from 'formik';
import * as Yup from 'yup';

import Box from '../Box';
import Dropzone, { ImageFile } from '../Dropzone/Dropzone';
import ErrorListener from '../Formik/ErrorListener';

interface Props {
  acceptedFiles: string;
  dropzoneTexts: DropzoneTexts;
  file?: File | null;
  id: string;
  previewUrl?: string;
  setFile: (file: File | null) => void;
  setPreviewUrl: (value?: string) => void;
  submit: () => void;
}

interface DropzoneTexts {
  content: string;
  description: string;
  title: string;
}

const getValidationSchema = () =>
  Yup.object().shape({
    file: Yup.mixed().required(),
  });

const FileUpload: React.FC<Props> = ({
  submit,
  setFile,
  file,
  previewUrl,
  setPreviewUrl,
  id,
  dropzoneTexts,
  acceptedFiles,
}) => {
  const initialValues = {
    file,
  };
  const validationSchema = getValidationSchema();
  return (
    <Formik
      onSubmit={submit}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize
      {...{ validationSchema, initialValues }}
    >
      {({ handleSubmit, setFieldValue }) => (
        <>
          <Box noValidate onSubmit={handleSubmit} tag="form" id={id}>
            <ErrorListener />
            <Box marginBottom="32px">
              <Dropzone
                {...{
                  previewUrl,
                  setPreviewUrl,
                  isFile: true,
                  maxSize: 102400,
                  onChange: (newFile?: File | ImageFile | null) => {
                    setFile(newFile as File);
                    setFieldValue('file', newFile?.arrayBuffer);
                  },
                  title: dropzoneTexts.title,
                  description: dropzoneTexts.description,
                  content: dropzoneTexts.content,
                }}
                accept={acceptedFiles}
              />
            </Box>
          </Box>
        </>
      )}
    </Formik>
  );
};

export default FileUpload;
