import { FC } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Img } from 'modules/Ui';

import DropView from './DropView';
import FileLoaded from './FileLoaded';
import messages from './messages';

interface Props {
  content: string;
  fileName: string;
  previewUrl?: string;
  isFile?: boolean;
}

const ContentView: FC<Props> = ({ previewUrl, isFile, fileName, content }) => {
  const { t } = useTranslations();
  if (previewUrl && !isFile) {
    return <Img alt={t(messages.addedLogo)} hide src={previewUrl} />;
  }
  if (previewUrl && isFile) {
    return <FileLoaded name={fileName} />;
  }
  return <DropView content={content} />;
};

export default ContentView;
