import { ReactNode } from 'react';

import fadein from 'modules/Theme/mixins/fadeIn';
import styled, { css } from 'modules/Theme/styled-components';

import Box from '../../Box';

interface FilterExpandableProps {
  children?: ReactNode;
  open: boolean;
  id: string;
  buttonId: string;
}

const animation = css`
  animation: ${fadein} 0.2s ease-in;
`;

const Wrapper = styled(Box)`
  ${({ open }) => open && animation};
  & > * {
    display: ${({ open }) => (open ? 'block' : 'none')};
  }
`;

const FilterExpandable = (props: FilterExpandableProps) => {
  const { children, open, id, buttonId } = props;
  return (
    <Wrapper
      {...{ id, open }}
      data-testid={id}
      aria-labelledby={buttonId}
      role="region"
    >
      {children}
    </Wrapper>
  );
};

export default FilterExpandable;
