import {
  ReactNode,
  DetailedHTMLProps as Props,
  ButtonHTMLAttributes as Attributes,
} from 'react';

import {
  BackgroundColorProps,
  border,
  BorderProps,
  display,
  DisplayProps,
  flexbox,
  FlexboxProps,
  grid,
  GridProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  system,
} from 'styled-system';

import {
  buttonPrimaryStyles,
  buttonSecondaryStyles,
  buttonStrokeStyles,
  buttonGostStyles,
  buttonGostDarkStyles,
  buttonLinkStyle,
  buttonCustomIconTextStyle,
} from 'modules/Theme/mixins/buttonImports';
import styled from 'modules/Theme/styled-components';

import Box from '../../Box';
import HtmlButton from '../../Html/Button';

export interface ButtonProps
  extends BorderProps,
    DisplayProps,
    FlexboxProps,
    GridProps,
    LayoutProps,
    PositionProps,
    SpaceProps,
    TypographyProps,
    Pick<BackgroundColorProps, 'backgroundColor'>,
    Omit<Props<Attributes<HTMLButtonElement>, HTMLButtonElement>, 'onClick'> {
  accessibleText?: string;
  active?: boolean;
  boxSizing?: 'content-box' | 'border-box';
  children?: string | ReactNode;
  className?: string;
  color?: string;
  columnGap?: {} | string;
  disabled?: boolean;
  gap?: {} | string;
  hide?: boolean;
  icon?: ReactNode;
  iconPosition?: 'start' | 'end';
  isUnderlined?: boolean;
  onClick?: (value: any) => void;
  placeItems?: {} | string;
  type?: 'button' | 'reset' | 'submit';
  variant?:
    | 'primary'
    | 'secondary'
    | 'stroke'
    | 'link'
    | 'ghost'
    | 'ghostDark'
    | 'light';
}

export const StyledButton = styled(HtmlButton)`
  ${({ variant }) => variant === 'primary' && buttonPrimaryStyles}
  ${({ variant }) => variant === 'secondary' && buttonSecondaryStyles}
  ${({ variant }) => variant === 'stroke' && buttonStrokeStyles}
  ${({ variant }) => variant === 'link' && buttonLinkStyle}
  ${({ variant }) => variant === 'ghost' && buttonGostStyles}
  ${({ variant }) => variant === 'ghostDark' && buttonGostDarkStyles}
  ${({ variant, icon }) => !variant && icon && buttonCustomIconTextStyle}
  ${({ boxSizing }) => boxSizing && `box-sizing: ${boxSizing};`}
  ${({ color }) => color && `color: ${color};`}
  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${backgroundColor};`}
  ${border}
  ${grid}
  ${display}
  ${flexbox}
  ${layout}
  ${position}
  ${space}
  ${typography}
  ${system({
    gap: {
      property: 'gap',
      transform: (value) => `${value}`,
    },
    columnGap: {
      property: 'columnGap',
      transform: (value) => `${value}`,
    },
    placeItems: {
      property: 'placeItems',
      scale: 'placeItems',
    },
  })}
  `;

const Button = (props: ButtonProps) => {
  const {
    accessibleText,
    active,
    boxSizing,
    children,
    className,
    hide,
    icon,
    fontWeight,
    iconPosition = 'start',
    type = 'button',
    variant,
    onClick,
    color,
    backgroundColor,
    ...rest
  } = props;
  return (
    <StyledButton
      active={active}
      aria-label={accessibleText}
      boxSizing={boxSizing}
      className={className}
      display={icon && 'flex'}
      flexDirection={
        icon && iconPosition === 'start' ? 'row-reverse' : undefined
      }
      icon={icon}
      type={type}
      variant={variant}
      onClick={onClick}
      color={color}
      backgroundColor={backgroundColor}
      fontWeight={fontWeight || 'normal'}
      {...{ ...rest }}
    >
      {variant === 'primary' ||
      variant === 'secondary' ||
      variant === 'ghost' ||
      variant === 'ghostDark' ||
      variant === 'stroke' ? (
        <Box
          color="inherit"
          fontSize="inherit"
          position="relative"
          tag="span"
          whiteSpace="nowrap"
          width={!icon && '100%'}
          textAlign="left"
        >
          {children}
        </Box>
      ) : (
        <>{children}</>
      )}

      {icon && (
        <Box
          color="inherit"
          height="24px"
          margin={iconPosition === 'end' ? '0 0 0 12px' : '0 12px 0 0'}
          position="relative"
          tag="span"
          width="24px"
        >
          {icon}
        </Box>
      )}
    </StyledButton>
  );
};

export default Button;
