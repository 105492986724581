import { useState } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Button, Box } from 'modules/Ui';
import { IconAdd } from 'modules/Ui/Icons';

import CarbonCopyFields from './CarbonCopyFields';
import { messages } from './messages';

const CarbonCopyBlock: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslations();
  return (
    <>
      <Button
        aria-controls="cc-block"
        aria-expanded={isOpen}
        icon={<IconAdd />}
        id="open-cc-block"
        marginBottom="12px"
        onClick={() => setIsOpen(!isOpen)}
        variant="ghost"
      >
        {t(messages.ccButton)}
      </Button>
      <Box aria-labelledby="open-cc-block" id="cc-block" role="region">
        {isOpen && <CarbonCopyFields />}
      </Box>
    </>
  );
};
export default CarbonCopyBlock;
