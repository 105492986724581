import { ReactNode } from 'react';

import { ResponsiveValue } from 'styled-system';

import { AccountingAccountModalProps } from 'modules/AccountingAccounts/components/AccountingAccountModal/types';
import {
  AccountDeleteModalProps,
  UpdateAccountingAccountModalProps,
} from 'modules/AccountingAccounts/components/UpdateAccountingAccountModal/types';
import { CancelInvitationModalProps } from 'modules/Bookkeeper/components/CancelInvitationModal/types';
import { DeleteClientModalProps } from 'modules/Bookkeeper/components/DeleteClientModal/types';
import { ResendInvitationModalProps } from 'modules/Bookkeeper/components/ResendInvitationModal/types';
import { AddSalesCodeModalProps } from 'modules/Bookkeeper/Exporter/components/AddSalesCodeModal/types';
import { SelectCustomerDialogProps } from 'modules/Bookkeeper/Exporter/components/SelectCustomerDialog/types';
import { ChangeDataBusinnessModalProps } from 'modules/Business/components/types';
import { UsersDeleteModalProps } from 'modules/BusinessUsers/UsersDeleteModal/types';
import { LimitContactProps } from 'modules/Contacts/Create/components/types';
import { CreateContactDialogProps } from 'modules/Contacts/CreateContactDialog/types';
import { ContactsDeleteModalProps } from 'modules/Contacts/Delete/components/ContactsDeleteModal/types';
import { SearchContactDialogProps } from 'modules/Contacts/SearchContactDialog/types';
import { BaiErrorsModalProps } from 'modules/Documents/components/BaiErrorsModal/BaiErrorsModalProps';
import { CreateRecurrentErrorModalProps } from 'modules/Documents/components/CreateRecurrentErrorModal/types';
import { CreateSerieModalProps } from 'modules/Documents/components/CreateSerieModal/types';
import {
  AddPaymentMethodModalProps,
  UpdatePaymentMethodModalProps,
} from 'modules/Documents/components/Form/AddPaymentMethodModal/types';
import { DetachProductModalProps } from 'modules/Documents/components/Form/components/DetachProductModal/types';
import { ChangeVatNumberModalProps } from 'modules/Documents/components/TwoStepsForm/FirstStep/components/TicketBaiOptions/ChangeVatNumberModal/ChangeVatNumberModalProps';
import { TaxRegimeWarningModalProps } from 'modules/Documents/components/TwoStepsForm/FirstStep/components/TicketBaiOptions/types';
import { DocumentsDeleteModalProps } from 'modules/Documents/Delete/components/DocumentsDeleteModal/types';
import { DocumentRectifyModalProps } from 'modules/Documents/Detail/components/DocumentRecitifyModal/types';
import { DocumentTrackingMobileModalProps } from 'modules/Documents/Detail/components/DocumentTrackingMobileModal/types';
import { DocumentTrackingModalProps } from 'modules/Documents/Detail/components/DocumentTrackingModal/types';
import { DocumentsDataElectronicModalProps } from 'modules/Documents/Electronic/components/DataElectronicModal/types';
import {
  DocumentsDownloadZipModalProps,
  MultipleZipsDownloadModalProps,
} from 'modules/Documents/List/components/DocumentsDownloadZipModal/types';
import { IssueDeliveryNoteDialogProps } from 'modules/Documents/List/components/IssueDeliveryNoteDialog/types';
import {
  SortOutExpensesDocumentsModalProps,
  SortOutExpensesRecurrentsModalProps,
} from 'modules/Documents/List/components/SortOutExpensesModal/types';
import { RecurrentSendingProps } from 'modules/Documents/Send/components/ConfigRecurrentSendingModal/types';
import { OcrInfoModalProps } from 'modules/Documents/Upload/components/Form/OcrInfoModal/types';
import { VoidDocumentModalProps } from 'modules/Documents/Void/components/VoidDocumentModal/types';
import { LoadSavedDataModalProps } from 'modules/Forms/components/LoadSavedDataModal/LoadSavedDataModalProps';
import {
  GenerateCredentialsProps,
  DeleteCredentialsProps,
} from 'modules/Integrations/components/ApiConfigurationForm/GenerateCredentialModal/types';
import { CreateDocumentPaymentModalProps } from 'modules/Payments/components/CreateDocumentPaymentModal/types';
import { CreatePaymentModalProps } from 'modules/Payments/components/CreatePaymentModal/types';
import { EditPaymentModalProps } from 'modules/Payments/components/EditPaymentModal/types';
import { DeletePaymentsModalProps } from 'modules/Payments/components/List/components/DeletePaymentsModal/types';
import { AddProductDialogProps } from 'modules/Products/AddProductDialog/types';
import { AddProductFromDocumentDialogProps } from 'modules/Products/AddProductFromDocumentDialog/types';
import { AddProductPlanErrorModalProps } from 'modules/Products/components/AddProductPlanErrorModal/type';
import { DeactivateStockModalProps } from 'modules/Products/components/DeactivateStockModal/type';
import { ChangeUsedProductModalProps } from 'modules/Products/components/Form/ChangeUsedProductModal/types';
import { ProductsDeleteModalProps } from 'modules/Products/Delete/components/ProductsDeleteModal/types';
import { AddContactModalProps } from 'modules/Quotes/components/AddContactModal/types';
import { RemittanceContactModalProps } from 'modules/Remittances/CreateRemittanceAddDocuments/components/RemittanceContactModal/types';
import { DeleteRemittanceModalProps } from 'modules/Remittances/DeleteRemittances/components/DeleteRemittanceModal/types';
import { RemittanceDocumentsDeleteModalProps } from 'modules/Remittances/Detail/components/RemittanceDocumentsDeleteModal/types';
import { DocumentsAlreadyInARemittanceModalProps } from 'modules/Remittances/DocumentsAlreadyInARemittanceModal/types';
import { SetRemittanceStatusModalProps } from 'modules/Remittances/RemittanceList/components/SetRemittanceStatusModal/types';
import { AddNewRemittanceModalProps } from 'modules/Remittances/types';
import { SaveChangesOptionsModalProps } from 'modules/Settings/components/SaveChangesOptionsModal/types';
import { PaymentsDeleteModalProps } from 'modules/Settings/Delete/components/PaymentsDeleteModal/types';
import { SeriesDeleteErrorModalProps } from 'modules/Settings/Delete/components/SeriesDeleteErrorModal/types';
import { SeriesDeleteModalProps } from 'modules/Settings/Delete/components/SeriesDeleteModal/types';
import {
  ChangePlanErrorModalProps,
  ChangePlanWarningModalProps,
} from 'modules/Subscriptions/Change/types';
import { CreatePlanErrorProps } from 'modules/Subscriptions/Create/components/CreatePlanErrorModal/CreatePlanErrorProps';
import { CreatePlanWarningProps } from 'modules/Subscriptions/Create/components/CreatePlanWarningModal/CreatePlanWarningProps';
import { InfoModalProps } from 'modules/Ui/InfoModal/types';
import {
  PdfViewerProps,
  TemplateViewerProps,
} from 'modules/Ui/PdfViewer/types';
import { ChangePasswordModalProps } from 'modules/Users/ChangePassword/types';
import { CurrentUserDeleteModalProps } from 'modules/Users/CurrentUserDeleteModal/types';

import { ConfirmBaiSubmissionModalProps } from '../Documents/BaiSubmission/components/ConfirmBaiSubmissionModal/types';
import { CompleteValidVatNumbersProps } from '../Documents/components/CompleteValidVatNumbers/types';
import { FixVatNumberModalProps } from '../Documents/components/FixVatNumberModal/FixVatNumberModalProps';
import { InvalidTaxRegimeModalProps } from '../Documents/components/InvalidTaxRegimeModal/InvalidTaxRegimeModalProps';
import { BaiFixVatNumberModalProps } from '../Documents/FixDocument/components/BaiFixVatNumberModal/BaiFixVatNumberModalProps';
import { DeleteMultiplePendingDocumentsModalProps } from '../Documents/Upload/components/DeleteMultiplePendingDocumentsModal/type';
import { DeletePendingDocumentModalProps } from '../Documents/Upload/components/DeletePendingDocumentModal/type';
import { ScanningDocumentModalProps } from '../Documents/Upload/components/ScanningDocumentModal/type';

export enum ModalTypes {
  ACCOUNTING_ACCOUNT = 'ACCOUNTING_ACCOUNT',
  ACTIONS_ISRECEIPT_MODAL = 'ACTIONS_ISRECEIPT_MODAL',
  ADD_CONTACT = 'ADD_CONTACT',
  ADD_PAYMENT_METHOD = 'ADD_PAYMENT_METHOD',
  ADD_PRODUCT_TO_DOCUMENT = 'ADD_PRODUCT_TO_DOCUMENT',
  ADD_PRODUCT_FROM_DOCUMENT = 'ADD_PRODUCT_FROM_DOCUMENT',
  ADD_REMITTANCE_MODAL = 'ADD_REMITTANCE_MODAL',
  ADD_REMITTANCE_PLAN_ERROR_MODAL = 'ADD_REMITTANCE_PLAN_ERROR_MODAL',
  ADD_PRODUCT_PLAN_ERROR_MODAL = 'ADD_PRODUCT_PLAN_ERROR_MODAL',
  ADD_SALES_MODAL = 'ADD_SALES_MODAL',
  BAI_CONNECTION = 'BAI_CONNECTION',
  BAI_VALIDATION_ERRORS = 'BAI_VALIDATION_ERRORS',
  BOOKKEEPER_DELETE_CLIENT_MODAL = 'BOOKKEEPER_DELETE_CLIENT_MODAL',
  BOOKKEEPER_SELECT_CUSTOMER = 'BOOKKEEPER_SELECT_CUSTOMER',
  CANCEL_INVITATION_MODAL = 'CANCEL_INVITATION_MODAL',
  CHANGE_BAI_VAT_NUMBER = 'CHANGE_BAI_VAT_NUMBER ',
  CHANGE_BILLING_EMAIL = 'CHANGE_BILLING_EMAIL',
  CHANGE_BUSINESS = 'CHANGE_BUSINESS',
  CHANGE_CARD = 'CHANGE_CARD',
  CHANGE_DATA_BUSINESS = 'CHANGE_DATA_BUSINESS ',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  CHANGE_PLAN_ERROR_MODAL = 'CHANGE_PLAN_ERROR_MODAL',
  CHANGE_PLAN_WARNING_MODAL = 'CHANGE_PLAN_WARNING_MODAL',
  COMPLETE_BAI_DATA = 'COMPLETE_BAI_DATA',
  SET_REMITTANCE_STATUS_MODAL = 'SET_REMITTANCE_STATUS_MODAL',
  COMPLETE_VALID_VAT_NUMBERS = 'COMPLETE_VALID_VAT_NUMBERS',
  CONFIG_RECURRENT_SENDING_MODAL = 'CONFIG_RECURRENT_SENDING_MODAL',
  CONFIGURE_SHOPIFY = 'CONFIGURE_SHOPIFY',
  CONFIRM_BAI_SUBMISSION = 'CONFIRM_BAI_SUBMISSION',
  CONFIRMATION_DIALOG = 'CONFIRMATION_DIALOG',
  CREATE_CONTACT = 'CREATE_CONTACT',
  CREATE_DOCUMENT_PAYMENT = 'CREATE_DOCUMENT_PAYMENT',
  CREATE_PAYMENT = 'CREATE_PAYMENT',
  CREATE_PLAN_ERROR = 'CREATE_PLAN_ERROR',
  CREATE_PLAN_WARNING = 'CREATE_PLAN_WARNING',
  CREATE_RECURRENT_ERROR_MODAL = 'CREATE_RECURRENT_ERROR_MODAL',
  CREATE_SERIE_MODAL = 'CREATE_SERIE_MODAL',
  CUSTOMIZE_TEMPLATE_PREVIEW = 'CUSTOMIZE_TEMPLATE_PREVIEW',
  DATA_ELECTRONIC = 'DATA_ELECTRONIC',
  DEACTIVATE_SHOPIFY_WARNING = 'DEACTIVATE_SHOPIFY_WARNING',
  DELETE_ACCOUNT = 'DELETE_ACCOUNT',
  DELETE_BUSINESS = 'DELETE_BUSINESS',
  DELETE_CONTACT_MODAL = 'DELETE_CONTACT_MODAL',
  DELETE_CONTACTS = 'DELETE_CONTACTS',
  DELETE_CREDENTIAL = 'DELETE_CREDENTIAL',
  DELETE_DOCUMENT_SERIE = 'DELETE_DOCUMENT_SERIE',
  DELETE_DOCUMENT_SERIE_ERROR = 'DELETE_DOCUMENT_SERIE_ERROR',
  DELETE_DOCUMENTS = 'DELETE_DOCUMENTS',
  DELETE_REMITTANCE_DOCUMENTS = 'DELETE_REMITTANCE_DOCUMENTS',
  DELETE_ME = 'DELETE_ME',
  DELETE_MULTIPLE_REVIEW_PENDING_DOCUMENT = 'DELETE_MULTIPLE_REVIEW_PENDING_DOCUMENT',
  DELETE_PAYMENT_METHOD = 'DELETE_PAYMENT_METHOD',
  DELETE_PAYMENTS = 'DELETE_PAYMENTS',
  DELETE_PRODUCTS = 'DELETE_PRODUCTS',
  DELETE_REVIEW_PENDING_DOCUMENT = 'DELETE_REVIEW_PENDING_DOCUMENT',
  DELETE_SUSCRIPTION = 'DELETE_SUSCRIPTION',
  DELETE_USER = 'DELETE_USER',
  DOCUMENT_TRACKING = 'DOCUMENT_TRACKING',
  DOCUMENT_TRACKING_MOBILE = 'DOCUMENT_TRACKING_MOBILE',
  DOWNLOAD_DOCUMENTS_ZIP = 'DOWNLOAD_DOCUMENTS_ZIP',
  EDIT_CONTACT_MODAL = 'EDIT_CONTACT_MODAL',
  EDIT_PAYMENT = 'EDIT_PAYMENT',
  EDIT_REMITTANCE_MODAL = 'EDIT_REMITTANCE_MODAL',
  EXPORTER_HELP_MODAL = 'EXPORTER_HELP_MODAL',
  FIX_BAI_VAT_NUMBER = 'FIX_BAI_VAT_NUMBER',
  FIX_VAT_NUMBER_MODAL = 'FIX_VAT_NUMBER_MODAL',
  GENERATE_CREDENTIALS = 'GENERATE_CREDENTIALS',
  INFORMATION_MODAL = 'INFORMATION_MODAL',
  INVALID_TAX_REGIME_MODAL = 'INVALID_TAX_REGIME_MODAL',
  ISSUE_DELIVERY_NOTE = 'ISSUE_DELIVERY_NOTE',
  LIMIT_CONTACTS = 'LIMIT_CONTACTS',
  MULTIPLE_ZIPS_DOWNLOAD = 'MULTIPLE_ZIPS_DOWNLOAD',
  NO_DOCUMENT_FOR_SCAN = 'NO_DOCUMENT_FOR_SCAN',
  OCR_INFO = 'OCR_INFO',
  OLD_APP_MODAL = 'OLD_APP_MODAL',
  PDF_PREVIEW = 'PDF_PREVIEW',
  RECTIFY_DOCUMENT = 'RECTIFY_DOCUMENT',
  REMITTANCE_CONTACT_MODAL = 'REMITTANCE_CONTACT_MODAL',
  RESEND_INVITATION_MODAL = 'RESEND_INVITATION_MODAL',
  SAVE_CHANGES_OPTIONS = 'SAVE_CHANGES_OPTIONS',
  SCAN_LIMIT_MODAL = 'SCAN_LIMIT_MODAL',
  SCANNING_DOCUMENT = 'SCANNING_DOCUMENT',
  SEARCH_CONTACTS = 'SEARCH_CONTACTS',
  SELECT_LANGUAGE = 'SELECT_LANGUAGE',
  SORT_OUT_EXPENSES_DOCUMENTS = 'SORT_OUT_EXPENSES_DOCUMENTS',
  SORT_OUT_EXPENSES_RECURRENTS = 'SORT_OUT_EXPENSES_RECURRENTS',
  SUGGESTION_INFO_MODAL = 'SUGGESTION_INFO_MODAL',
  TAX_REGIME_MODAL = 'TAX_REGIME_MODAL',
  TEST_INVOICE = 'TEST_INVOICE',
  UPDATE_ACCOUNTING_ACCOUNT = 'UPDATE_ACCOUNTING_ACCOUNT',
  UPDATE_PAYMENT_METHOD = 'UPDATE_PAYMENT_METHOD',
  UPGRADE_FOR_MORE_SCANS = 'UPGRADE_FOR_MORE_SCANS',
  VOID_DOCUMENT = 'VOID_DOCUMENT',
  DELETE_REMITTANCES = 'DELETE_REMITTANCES',
  DOCUMENTS_ALREADY_IN_REMITTANCE = 'DOCUMENTS_ALREADY_IN_REMITTANCE',
  CHANGE_USED_PRODUCT = 'CHANGE_USED_PRODUCT',
  PRODUCT_ZERO_STATE_DIALOG = 'PRODUCT_ZERO_STATE_DIALOG',
  DETACH_PRODUCT = 'DETACH_PRODUCT',
  RECURRENT_PLAN_ERROR = 'RECURRENT_PLAN_ERROR',
  LOAD_SAVED_FORM_DATA = 'LOAD_SAVED_FORM_DATA',
  DEACTIVATE_STOCK = 'DEACTIVATE_STOCK',
}

type ConfirmationDialog = {
  cancelText?: string;
  confirmText?: string;
  id?: string;
  onCancel?: () => void;
  onCloseClick?: () => void;
  onConfirm: (close: () => void) => void;
  subtitle?: string;
  text?: ReactNode | string;
  title: string;
  hasTitleEllipsis?: boolean;
  hideSecondary?: boolean;
  type: ModalTypes.CONFIRMATION_DIALOG;
  width?: ResponsiveValue<string>;
};

type ProductsDeleteModal = {
  type: ModalTypes.DELETE_PRODUCTS;
} & ProductsDeleteModalProps;

type ContactsDeleteModal = {
  type: ModalTypes.DELETE_CONTACTS;
} & ContactsDeleteModalProps;

type AccountDeleteModal = {
  type: ModalTypes.DELETE_ACCOUNT;
} & AccountDeleteModalProps;

type SearchContactDialog = {
  type: ModalTypes.SEARCH_CONTACTS;
} & SearchContactDialogProps;

type CreateContactDialog = {
  type: ModalTypes.CREATE_CONTACT;
} & CreateContactDialogProps;

type DocumentsDeleteModal = {
  type: ModalTypes.DELETE_DOCUMENTS;
} & DocumentsDeleteModalProps;

type DocumentsDataElectronicModal = {
  type: ModalTypes.DATA_ELECTRONIC;
} & DocumentsDataElectronicModalProps;

type SeriesDeleteModal = {
  type: ModalTypes.DELETE_DOCUMENT_SERIE;
} & SeriesDeleteModalProps;

type SeriesDeleteErrorModal = {
  type: ModalTypes.DELETE_DOCUMENT_SERIE_ERROR;
} & SeriesDeleteErrorModalProps;

type PaymentsMethodDeleteModal = {
  type: ModalTypes.DELETE_PAYMENT_METHOD;
} & PaymentsDeleteModalProps;

type SaveChangesOptionsModal = {
  type: ModalTypes.SAVE_CHANGES_OPTIONS;
} & SaveChangesOptionsModalProps;

type AddProductDialog = {
  type: ModalTypes.ADD_PRODUCT_TO_DOCUMENT;
} & AddProductDialogProps;

type ChangeBusiness = {
  type: ModalTypes.CHANGE_BUSINESS;
};

type ChangeBillingEmail = {
  type: ModalTypes.CHANGE_BILLING_EMAIL;
};

type LimitContacts = {
  type: ModalTypes.LIMIT_CONTACTS;
} & LimitContactProps;

type CreatePlanError = {
  type: ModalTypes.CREATE_PLAN_ERROR;
} & CreatePlanErrorProps;

type CreatePlanWarning = {
  type: ModalTypes.CREATE_PLAN_WARNING;
} & CreatePlanWarningProps;

type DocumentsDownloadZipModal = {
  type: ModalTypes.DOWNLOAD_DOCUMENTS_ZIP;
} & DocumentsDownloadZipModalProps;

type UserDeleteModal = {
  type: ModalTypes.DELETE_USER;
} & UsersDeleteModalProps;

type CurrentUserDeleteModal = {
  type: ModalTypes.DELETE_ME;
} & CurrentUserDeleteModalProps;

type ChangePlanErrorModal = {
  type: ModalTypes.CHANGE_PLAN_ERROR_MODAL;
} & ChangePlanErrorModalProps;

type ChangePlanWarningModal = {
  type: ModalTypes.CHANGE_PLAN_WARNING_MODAL;
} & ChangePlanWarningModalProps;

type ChangePasswordModal = {
  type: ModalTypes.CHANGE_PASSWORD;
} & ChangePasswordModalProps;

type BusinessDeleteModal = {
  type: ModalTypes.DELETE_BUSINESS;
};

type CancelSubscriptionModal = {
  type: ModalTypes.DELETE_SUSCRIPTION;
};

type CreateRecurrentErrorModal = {
  type: ModalTypes.CREATE_RECURRENT_ERROR_MODAL;
} & CreateRecurrentErrorModalProps;

type InformationModal = {
  type: ModalTypes.INFORMATION_MODAL;
} & InfoModalProps;

type OldAppModal = {
  type: ModalTypes.OLD_APP_MODAL;
};

type AddSalesCodeModal = {
  type: ModalTypes.ADD_SALES_MODAL;
} & AddSalesCodeModalProps;

type TemplateViewer = {
  type: ModalTypes.CUSTOMIZE_TEMPLATE_PREVIEW;
} & TemplateViewerProps;

type PdfViewer = {
  type: ModalTypes.PDF_PREVIEW;
} & PdfViewerProps;

type CreateSerieModal = {
  type: ModalTypes.CREATE_SERIE_MODAL;
} & CreateSerieModalProps;

type ResendInvitationModal = {
  type: ModalTypes.RESEND_INVITATION_MODAL;
} & ResendInvitationModalProps;

type CancelInvitationModal = {
  type: ModalTypes.CANCEL_INVITATION_MODAL;
} & CancelInvitationModalProps;

type DeleteClientModal = {
  type: ModalTypes.BOOKKEEPER_DELETE_CLIENT_MODAL;
} & DeleteClientModalProps;

type AddContactModal = {
  type: ModalTypes.ADD_CONTACT;
} & AddContactModalProps;

type SortOutExpensesDocumentsModal = {
  type: ModalTypes.SORT_OUT_EXPENSES_DOCUMENTS;
} & SortOutExpensesDocumentsModalProps;

type SortOutExpensesRecurrentsModal = {
  type: ModalTypes.SORT_OUT_EXPENSES_RECURRENTS;
} & SortOutExpensesRecurrentsModalProps;

type ConfigRecurrentSendingModal = {
  type: ModalTypes.CONFIG_RECURRENT_SENDING_MODAL;
} & RecurrentSendingProps;

type DocumentTrackingModal = {
  type: ModalTypes.DOCUMENT_TRACKING;
} & DocumentTrackingModalProps;

type DocumentTrackingMobileModal = {
  type: ModalTypes.DOCUMENT_TRACKING_MOBILE;
} & DocumentTrackingMobileModalProps;

type AddPaymentMethodModal = {
  type: ModalTypes.ADD_PAYMENT_METHOD;
} & AddPaymentMethodModalProps;

type UpdatePaymentMethodModal = {
  type: ModalTypes.UPDATE_PAYMENT_METHOD;
} & UpdatePaymentMethodModalProps;

type CompleteValidVatNumbers = {
  type: ModalTypes.COMPLETE_VALID_VAT_NUMBERS;
} & CompleteValidVatNumbersProps;

type VoidDocumentModal = {
  type: ModalTypes.VOID_DOCUMENT;
} & VoidDocumentModalProps;

type SelectLanguageModal = {
  type: ModalTypes.SELECT_LANGUAGE;
};

type ConfirmBaiSubmission = {
  type: ModalTypes.CONFIRM_BAI_SUBMISSION;
} & ConfirmBaiSubmissionModalProps;

type OcrInfoModal = {
  type: ModalTypes.OCR_INFO;
} & OcrInfoModalProps;

type NoDocumentForScanModal = {
  type: ModalTypes.NO_DOCUMENT_FOR_SCAN;
};

type ScanningDocument = {
  type: ModalTypes.SCANNING_DOCUMENT;
} & ScanningDocumentModalProps;

type UpgradeForMoreScans = {
  type: ModalTypes.UPGRADE_FOR_MORE_SCANS;
};

type ScanLimit = {
  type: ModalTypes.SCAN_LIMIT_MODAL;
};

type TestInvoiceModal = {
  type: ModalTypes.TEST_INVOICE;
};

type RemittanceContactModal = {
  type: ModalTypes.REMITTANCE_CONTACT_MODAL;
} & RemittanceContactModalProps;

type DeleteReviewPendingModal = {
  type: ModalTypes.DELETE_REVIEW_PENDING_DOCUMENT;
} & DeletePendingDocumentModalProps;

type DeleteMultipleReviewPendingModal = {
  type: ModalTypes.DELETE_MULTIPLE_REVIEW_PENDING_DOCUMENT;
} & DeleteMultiplePendingDocumentsModalProps;

type SuggestionsInfoModal = {
  type: ModalTypes.SUGGESTION_INFO_MODAL;
};
type BaiValidationErrors = {
  type: ModalTypes.BAI_VALIDATION_ERRORS;
} & BaiErrorsModalProps;

type ExporterHelpModal = {
  video?: string;
  type: ModalTypes.EXPORTER_HELP_MODAL;
};
type TaxRegimeModal = {
  type: ModalTypes.TAX_REGIME_MODAL;
} & TaxRegimeWarningModalProps;

type BaiFixVatNumberModal = {
  type: ModalTypes.FIX_BAI_VAT_NUMBER;
} & BaiFixVatNumberModalProps;

type InvalidTaxRegimeModal = {
  type: ModalTypes.INVALID_TAX_REGIME_MODAL;
} & InvalidTaxRegimeModalProps;

type ActionsIsReceiptModal = {
  type: ModalTypes.ACTIONS_ISRECEIPT_MODAL;
};

type BaiChangeVatNumberModal = {
  type: ModalTypes.CHANGE_BAI_VAT_NUMBER;
} & ChangeVatNumberModalProps;

type ChangeDataBusiness = {
  type: ModalTypes.CHANGE_DATA_BUSINESS;
} & ChangeDataBusinnessModalProps;

type FixVatNumberModal = {
  type: ModalTypes.FIX_VAT_NUMBER_MODAL;
} & FixVatNumberModalProps;

type DocumentRectifyModal = {
  type: ModalTypes.RECTIFY_DOCUMENT;
} & DocumentRectifyModalProps;

type ChangeCardModal = {
  type: ModalTypes.CHANGE_CARD;
};

type ConfigureShopifyModal = {
  type: ModalTypes.CONFIGURE_SHOPIFY;
};
type BaiConnectionModal = {
  type: ModalTypes.BAI_CONNECTION;
};
type DeactivateShopifyWarningModal = {
  type: ModalTypes.DEACTIVATE_SHOPIFY_WARNING;
};

type GenerateCredentialModal = {
  type: ModalTypes.GENERATE_CREDENTIALS;
} & GenerateCredentialsProps;

type DeleteCredentialModal = {
  type: ModalTypes.DELETE_CREDENTIAL;
} & DeleteCredentialsProps;

type SelectCustomerDialog = {
  type: ModalTypes.BOOKKEEPER_SELECT_CUSTOMER;
} & SelectCustomerDialogProps;

type MultipleZipsDownloadModal = {
  type: ModalTypes.MULTIPLE_ZIPS_DOWNLOAD;
} & MultipleZipsDownloadModalProps;

type IssueDeliveryNoteDialog = {
  type: ModalTypes.ISSUE_DELIVERY_NOTE;
} & IssueDeliveryNoteDialogProps;

type CreatePaymentModal = {
  type: ModalTypes.CREATE_DOCUMENT_PAYMENT;
} & CreateDocumentPaymentModalProps;

type CreatePaymentFromListModal = {
  type: ModalTypes.CREATE_PAYMENT;
} & CreatePaymentModalProps;

type EditPaymentModal = {
  type: ModalTypes.EDIT_PAYMENT;
} & EditPaymentModalProps;

type AccountingAccountModal = {
  type: ModalTypes.ACCOUNTING_ACCOUNT;
} & AccountingAccountModalProps;

type UpdateAccountingAccountModal = {
  type: ModalTypes.UPDATE_ACCOUNTING_ACCOUNT;
} & UpdateAccountingAccountModalProps;

type DeletePaymentModal = {
  type: ModalTypes.DELETE_PAYMENTS;
} & DeletePaymentsModalProps;

type AddRemittanceModal = {
  type: ModalTypes.ADD_REMITTANCE_MODAL;
} & AddNewRemittanceModalProps;

type AddRemittancePlanErrorModal = {
  type: ModalTypes.ADD_REMITTANCE_PLAN_ERROR_MODAL;
};

type AddProductPlanErrorModal = {
  type: ModalTypes.ADD_PRODUCT_PLAN_ERROR_MODAL;
} & AddProductPlanErrorModalProps;

type EditRemittanceModal = {
  type: ModalTypes.EDIT_REMITTANCE_MODAL;
} & AddNewRemittanceModalProps;

type SetRemittanceStatusModal = {
  type: ModalTypes.SET_REMITTANCE_STATUS_MODAL;
} & SetRemittanceStatusModalProps;

type DeleteRemittanceModal = {
  type: ModalTypes.DELETE_REMITTANCES;
} & DeleteRemittanceModalProps;

type DeleteRemittanceDocuments = {
  type: ModalTypes.DELETE_REMITTANCE_DOCUMENTS;
} & RemittanceDocumentsDeleteModalProps;

type DocumentsAlreadyInARemittanceModal = {
  type: ModalTypes.DOCUMENTS_ALREADY_IN_REMITTANCE;
} & DocumentsAlreadyInARemittanceModalProps;

type ChangeUsedProductModal = {
  type: ModalTypes.CHANGE_USED_PRODUCT;
} & ChangeUsedProductModalProps;

type DetachProductModal = {
  type: ModalTypes.DETACH_PRODUCT;
} & DetachProductModalProps;

type AddProductFromDocument = {
  type: ModalTypes.ADD_PRODUCT_FROM_DOCUMENT;
} & AddProductFromDocumentDialogProps;

type ProductsZeroStateDialog = {
  type: ModalTypes.PRODUCT_ZERO_STATE_DIALOG;
};

type CreateRecurrentsPlanErrorModal = {
  type: ModalTypes.RECURRENT_PLAN_ERROR;
};

type LoadSavedFormDataModal = {
  type: ModalTypes.LOAD_SAVED_FORM_DATA;
} & LoadSavedDataModalProps;

type DeactivateStockModal = {
  type: ModalTypes.DEACTIVATE_STOCK;
} & DeactivateStockModalProps;

export type ModalProps =
  | AccountDeleteModal
  | AccountingAccountModal
  | ActionsIsReceiptModal
  | AddContactModal
  | AddPaymentMethodModal
  | AddProductPlanErrorModal
  | AddProductDialog
  | AddProductFromDocument
  | AddRemittanceModal
  | AddRemittancePlanErrorModal
  | AddSalesCodeModal
  | BaiChangeVatNumberModal
  | BaiConnectionModal
  | BaiFixVatNumberModal
  | BaiValidationErrors
  | BusinessDeleteModal
  | CancelInvitationModal
  | CancelSubscriptionModal
  | ChangeBillingEmail
  | ChangeBusiness
  | ChangeCardModal
  | ChangeDataBusiness
  | ChangePasswordModal
  | ChangePlanErrorModal
  | ChangePlanWarningModal
  | CompleteValidVatNumbers
  | ConfigRecurrentSendingModal
  | ConfigureShopifyModal
  | ConfirmationDialog
  | ConfirmBaiSubmission
  | ContactsDeleteModal
  | CreateContactDialog
  | CreatePaymentFromListModal
  | CreatePaymentModal
  | CreatePlanError
  | CreatePlanWarning
  | CreateRecurrentErrorModal
  | CreateRecurrentsPlanErrorModal
  | CreateSerieModal
  | CurrentUserDeleteModal
  | DeactivateShopifyWarningModal
  | DeleteClientModal
  | DeleteCredentialModal
  | DeleteMultipleReviewPendingModal
  | DeletePaymentModal
  | DeleteRemittanceDocuments
  | DeleteRemittanceModal
  | DeleteReviewPendingModal
  | DocumentRectifyModal
  | DocumentsAlreadyInARemittanceModal
  | DocumentsDataElectronicModal
  | DocumentsDeleteModal
  | DocumentsDownloadZipModal
  | DocumentTrackingMobileModal
  | DocumentTrackingModal
  | EditPaymentModal
  | EditRemittanceModal
  | ExporterHelpModal
  | FixVatNumberModal
  | GenerateCredentialModal
  | InformationModal
  | InvalidTaxRegimeModal
  | IssueDeliveryNoteDialog
  | LimitContacts
  | MultipleZipsDownloadModal
  | NoDocumentForScanModal
  | OcrInfoModal
  | OldAppModal
  | PaymentsMethodDeleteModal
  | PdfViewer
  | ProductsDeleteModal
  | RemittanceContactModal
  | ResendInvitationModal
  | SaveChangesOptionsModal
  | ScanLimit
  | ScanningDocument
  | SearchContactDialog
  | SelectCustomerDialog
  | SelectLanguageModal
  | SeriesDeleteErrorModal
  | SeriesDeleteModal
  | SetRemittanceStatusModal
  | SortOutExpensesDocumentsModal
  | SortOutExpensesRecurrentsModal
  | SuggestionsInfoModal
  | TaxRegimeModal
  | TemplateViewer
  | TestInvoiceModal
  | UpdateAccountingAccountModal
  | UpdatePaymentMethodModal
  | UpgradeForMoreScans
  | UserDeleteModal
  | ChangeUsedProductModal
  | DetachProductModal
  | LoadSavedFormDataModal
  | VoidDocumentModal
  | DeactivateStockModal
  | ProductsZeroStateDialog;

enum ActionTypes {
  SHOW_MODAL = 'gofre/modals/SHOW_MODAL',
  HIDE_MODAL = 'gofre/modals/HIDE_MODAL',
}

export default ActionTypes;
