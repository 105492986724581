import { ReactNode } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import useTheme from 'modules/Theme/hooks/useTheme';
import { accessibleHidden } from 'modules/Theme/mixins';
import hoverEffect from 'modules/Theme/mixins/hoverEffect';
import styled, { css } from 'modules/Theme/styled-components';
import useClosesDropDownOnClickOutside from 'modules/Ui/useClosesExpandableOnClickOutside';

import Box from '../Box';
import Button from '../Form/Button';
import { IconArrow } from '../Icons';
import Link from '../Link';
import Text from '../Text';
import { zIndex } from '../zIndex';
import messages from './messages';

interface DropdownButtonProps {
  actionText: string;
  actionUrl: string;
  children?: ReactNode;
  dropdownVariant?: 'primary' | 'secondary';
  icon?: ReactNode;
  initiallyOpen?: boolean;
}

const contentHide = css`
  ${accessibleHidden}
`;

export const variantPrimary = css`
  --button-background: ${(props) => props.theme.colors.accent400};
  --button-hover-background: ${(props) => props.theme.colors.accent500};
  --button-color: ${(props) => props.theme.colors.primary500};
`;

export const variantSecondary = css`
  --button-background: ${(props) => props.theme.colors.brand500};
  --button-hover-background: ${(props) => props.theme.colors.brand600};
  --button-color: ${(props) => props.theme.colors.gray0};
`;

const hover = css`
  background-color: var(--button-background);
  @media (hover: hover) {
    &:hover {
      background-color: var(--button-hover-background);
    }
  }
  &:focus {
    background-color: var(--button-hover-background);
  }
`;

const StyledButton = styled(Button)`
  ${hover}
  color: var(--button-color);
  ${hoverEffect('0 8px 8px 0')}
`;

const StyledLink = styled(Link)`
  ${hover}
  color: var(--button-color);
  ${hoverEffect('8px 0 0 8px')}
`;

const StyledPoup = styled(Box)`
  ${({ hide }) => (hide ? contentHide : undefined)}
`;
const StyledDropdown = styled(Box)<DropdownButtonProps>`
  ${({ dropdownVariant }) => dropdownVariant === 'primary' && variantPrimary}
  ${({ dropdownVariant }) =>
    dropdownVariant === 'secondary' && variantSecondary}
`;

export const DropdownButton = ({
  actionText,
  actionUrl,
  children,
  dropdownVariant = 'primary',
  icon,
  initiallyOpen,
}: DropdownButtonProps) => {
  const { t } = useTranslations();
  const id = 'dropdown';
  const { open, toggleDropDown, ref } =
    useClosesDropDownOnClickOutside(initiallyOpen);
  const theme = useTheme();
  return (
    <StyledDropdown
      dropdownVariant={dropdownVariant}
      expanded={open}
      minWidth="288px"
      position="relative"
      ref={ref}
    >
      <Box display="grid" gridTemplateColumns="1fr 56px" height="56px">
        <StyledLink
          alignItems="center"
          borderRadius="8px 0 0 8px"
          columnGap="12px"
          display="grid"
          gridTemplateColumns="24px 1fr"
          height="100%"
          paddingLeft="20px"
          position="relative"
          to={actionUrl}
        >
          <Box position="relative" size="24px" tag="span">
            {icon}
          </Box>
          <Text
            color="inherit"
            fontSize={16}
            lineHeight={20}
            paddingRight="8px"
            position="relative"
            tag="span"
          >
            {actionText}
          </Text>
        </StyledLink>
        <StyledButton
          aria-controls={`${id}-controls`}
          aria-expanded={open}
          aria-label={open ? t(messages.open) : t(messages.close)}
          borderLeftWidth="1px"
          borderLeftStyle="solid"
          borderLeftColor={
            dropdownVariant === 'primary' ? 'accent500' : 'brand600'
          }
          borderRadius="0 8px 8px 0"
          data-testid={id}
          id={`${id}-controls-content`}
          onClick={toggleDropDown}
          position="relative"
        >
          <IconArrow transform={open ? 'rotate(180deg)' : 'rotate(0deg)'} />
        </StyledButton>
      </Box>
      <StyledPoup
        aria-labelledby={`${id}-content`}
        backgroundColor={open && 'gray0'}
        border={open && `1px solid ${theme.colors.primary100}`}
        borderRadius={open && '8px'}
        boxShadow={open && `0px 4px 6px ${theme.colors.shadow}`}
        color="initial"
        fadeIn
        hide={!open}
        id={`${id}-controls`}
        marginTop={open && '4px'}
        open={open}
        overflow="hidden"
        position={open ? 'absolute' : undefined}
        right={open ? '0' : undefined}
        role="region"
        width={open ? '100%' : undefined}
        zIndex={open ? `${zIndex.ARROWBOX}` : '-1'}
      >
        <Box
          contentVisibility={!open ? 'hidden' : undefined}
          display="grid"
          padding={open && '24px 20px 24px 20px'}
          rowGap="30px"
          tag="ul"
        >
          {children}
        </Box>
      </StyledPoup>
    </StyledDropdown>
  );
};
