export enum TaxType {
  IVA = 'IVA',
  IGIC = 'IGIC',
  IPSI = 'IPSI',
}

export enum TaxKey {
  IVA_0 = 'IVA_0',
  IVA_4 = 'IVA_4',
  IVA_5 = 'IVA_5',
  IVA_10 = 'IVA_10',
  IVA_21 = 'IVA_21',
  IGIC_0 = 'IGIC_0',
  IGIC_3 = 'IGIC_3',
  IGIC_5 = 'IGIC_5',
  IGIC_7 = 'IGIC_7',
  IGIC_9_5 = 'IGIC_9_5',
  IGIC_13_5 = 'IGIC_13_5',
  IGIC_15 = 'IGIC_15',
  IGIC_20 = 'IGIC_20',
  IGIC_35 = 'IGIC_35',
  IPSI_0 = 'IPSI_0',
  IPSI_0_5 = 'IPSI_0_5',
  IPSI_1 = 'IPSI_1',
  IPSI_2 = 'IPSI_2',
  IPSI_3 = 'IPSI_3',
  IPSI_3_5 = 'IPSI_3_5',
  IPSI_4 = 'IPSI_4',
  IPSI_5 = 'IPSI_5',
  IPSI_6 = 'IPSI_6',
  IPSI_7 = 'IPSI_7',
  IPSI_8 = 'IPSI_8',
  IPSI_9 = 'IPSI_9',
  IPSI_10 = 'IPSI_10',
}

export interface TaxOption {
  label: string;
  amount: number;
  type: TaxType;
  value: TaxKey;
  hide?: boolean;
  salesEqTaxPercentage?: number;
  onlyExpenses?: boolean;
}

export interface TaxOptions {
  [key: string]: TaxOption;
}

export const taxOptions: TaxOptions = {
  [TaxKey.IVA_0]: {
    label: '0% IVA',
    amount: 0,
    type: TaxType.IVA,
    value: TaxKey.IVA_0,
    salesEqTaxPercentage: 0,
  },
  [TaxKey.IVA_4]: {
    label: '4% IVA',
    amount: 4,
    type: TaxType.IVA,
    value: TaxKey.IVA_4,
    salesEqTaxPercentage: 0.5,
  },
  [TaxKey.IVA_5]: {
    label: '5% IVA',
    amount: 5,
    type: TaxType.IVA,
    value: TaxKey.IVA_5,
    salesEqTaxPercentage: 0.62,
  },
  [TaxKey.IVA_10]: {
    label: '10% IVA',
    amount: 10,
    type: TaxType.IVA,
    value: TaxKey.IVA_10,
    salesEqTaxPercentage: 1.4,
  },
  [TaxKey.IVA_21]: {
    label: '21% IVA',
    amount: 21,
    type: TaxType.IVA,
    value: TaxKey.IVA_21,
    salesEqTaxPercentage: 5.2,
  },
  [TaxKey.IGIC_0]: {
    label: '0% IGIC',
    amount: 0,
    type: TaxType.IGIC,
    value: TaxKey.IGIC_0,
  },
  [TaxKey.IGIC_3]: {
    label: '3% IGIC',
    amount: 3,
    type: TaxType.IGIC,
    value: TaxKey.IGIC_3,
  },
  [TaxKey.IGIC_5]: {
    label: '5% IGIC',
    amount: 5,
    type: TaxType.IGIC,
    value: TaxKey.IGIC_5,
  },
  [TaxKey.IGIC_7]: {
    label: '7% IGIC',
    amount: 7,
    type: TaxType.IGIC,
    value: TaxKey.IGIC_7,
  },
  [TaxKey.IGIC_9_5]: {
    label: '9,5% IGIC',
    amount: 9.5,
    type: TaxType.IGIC,
    value: TaxKey.IGIC_9_5,
  },
  [TaxKey.IGIC_13_5]: {
    label: '13,5% IGIC',
    amount: 13.5,
    type: TaxType.IGIC,
    value: TaxKey.IGIC_13_5,
  },
  [TaxKey.IGIC_15]: {
    label: '15% IGIC',
    amount: 15,
    type: TaxType.IGIC,
    value: TaxKey.IGIC_15,
  },
  [TaxKey.IGIC_20]: {
    label: '20% IGIC',
    amount: 20,
    type: TaxType.IGIC,
    value: TaxKey.IGIC_20,
  },
  [TaxKey.IGIC_35]: {
    label: '35% IGIC',
    amount: 35,
    type: TaxType.IGIC,
    value: TaxKey.IGIC_35,
  },
  [TaxKey.IPSI_0]: {
    label: '0% IPSI',
    amount: 0,
    type: TaxType.IPSI,
    value: TaxKey.IPSI_0,
  },
  [TaxKey.IPSI_0_5]: {
    label: '0.5% IPSI',
    amount: 0.5,
    type: TaxType.IPSI,
    value: TaxKey.IPSI_0_5,
  },
  [TaxKey.IPSI_1]: {
    label: '1% IPSI',
    amount: 1,
    type: TaxType.IPSI,
    value: TaxKey.IPSI_1,
  },
  [TaxKey.IPSI_2]: {
    label: '2% IPSI',
    amount: 2,
    type: TaxType.IPSI,
    value: TaxKey.IPSI_2,
  },
  [TaxKey.IPSI_3]: {
    label: '3% IPSI',
    amount: 3,
    type: TaxType.IPSI,
    value: TaxKey.IPSI_3,
  },
  [TaxKey.IPSI_3_5]: {
    label: '3.5% IPSI',
    amount: 3.5,
    type: TaxType.IPSI,
    value: TaxKey.IPSI_3_5,
  },
  [TaxKey.IPSI_4]: {
    label: '4% IPSI',
    amount: 4,
    type: TaxType.IPSI,
    value: TaxKey.IPSI_4,
  },
  [TaxKey.IPSI_5]: {
    label: '5% IPSI',
    amount: 5,
    type: TaxType.IPSI,
    value: TaxKey.IPSI_5,
  },
  [TaxKey.IPSI_6]: {
    label: '6% IPSI',
    amount: 6,
    type: TaxType.IPSI,
    value: TaxKey.IPSI_6,
  },
  [TaxKey.IPSI_7]: {
    label: '7% IPSI',
    amount: 7,
    type: TaxType.IPSI,
    value: TaxKey.IPSI_7,
  },
  [TaxKey.IPSI_8]: {
    label: '8% IPSI',
    amount: 8,
    type: TaxType.IPSI,
    value: TaxKey.IPSI_8,
    hide: true,
  },
  [TaxKey.IPSI_9]: {
    label: '9% IPSI',
    amount: 9,
    type: TaxType.IPSI,
    value: TaxKey.IPSI_9,
  },
  [TaxKey.IPSI_10]: {
    label: '10% IPSI',
    amount: 10,
    type: TaxType.IPSI,
    value: TaxKey.IPSI_10,
  },
};

export const getVisibleTaxOptions = ({
  currentTax,
  onlyExpenses,
  hasElectronicProvider = false,
}: {
  currentTax: TaxKey;
  onlyExpenses?: boolean;
  hasElectronicProvider?: boolean;
}): TaxOption[] => {
  const filteredOptions = Object.values(taxOptions).filter((tax) => {
    if (tax.onlyExpenses === true && !onlyExpenses) {
      return false;
    }
    return !tax.hide || tax.value === currentTax;
  });

  if (hasElectronicProvider) {
    return filteredOptions.filter((tax) => tax.type === TaxType.IVA);
  }

  return filteredOptions;
};

export const defaultTaxOption = taxOptions.IVA_21;

export const salesEqualizationTax = {
  [TaxKey.IVA_4]: 0.5,
  [TaxKey.IVA_5]: 0.62,
  [TaxKey.IVA_10]: 1.4,
  [TaxKey.IVA_21]: 5.2,
};

export interface TaxLine {
  base: number;
  tax: TaxOption;
  saleEqualization?: number;
  saleEqualizationAmount: number;
  taxAmount: number;
}

export function isTaxKey(key: any): key is TaxKey {
  return Object.values(TaxKey).includes(key);
}
