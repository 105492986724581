import IconInfo from './IconInfo';

export interface IconSpamProps {
  color?: string;
  size?: number;
}

const IconSpam = (props: IconSpamProps) => {
  const { size, color, ...rest } = props;
  return (
    <IconInfo
      borderWidth={2}
      color={color}
      rotate={180}
      size={size}
      {...rest}
    />
  );
};

export default IconSpam;
