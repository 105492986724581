import React, { ReactNode } from 'react';
import { Visible } from 'react-grid-system';

import Box from 'modules/Ui/Box';
import { IconArrowSecondary } from 'modules/Ui/Icons';
import Img from 'modules/Ui/Img';
import Text from 'modules/Ui/Text';

import { VariantType } from '../variant';

interface ProductInfoBannerProps {
  actionColor?: string;
  actionText?: string;
  children?: ReactNode;
  image: string;
  isImageVisibleInMobile?: boolean;
  description: string;
  title?: string;
  variant?: VariantType;
}

const ProductInfoBannerContent: React.FC<ProductInfoBannerProps> = (props) => {
  const {
    actionColor,
    actionText,
    children,
    image,
    isImageVisibleInMobile,
    description,
    title,
    variant,
  } = props;
  return (
    <>
      {isImageVisibleInMobile ? (
        <Box
          display={
            variant === VariantType.HORIZONTAL
              ? { md: 'flex', lg: 'initial' }
              : 'initial'
          }
          height={variant !== VariantType.BLOCK ? '100%' : 'auto'}
          justifyContent={
            variant === VariantType.HORIZONTAL
              ? { md: 'flex-end', lg: 'initial' }
              : 'initial'
          }
          overflow="hidden"
          tag="span"
          width={variant !== VariantType.BLOCK ? '50%' : '100%'}
        >
          <Img
            alt=""
            ariaHidden={true}
            height={variant !== VariantType.BLOCK ? '100%' : 'auto'}
            src={image}
            width={variant !== VariantType.BLOCK ? 'auto' : '100%'}
          />
        </Box>
      ) : (
        <Visible sm md lg xl xxl>
          <Box
            display={{ md: 'flex', lg: 'initial' }}
            justifyContent={{ md: 'flex-end', lg: 'initial' }}
            overflow="hidden"
            tag="span"
            width="50%"
          >
            <Img
              alt=""
              ariaHidden={true}
              height={variant !== VariantType.BLOCK ? '100%' : 'auto'}
              minHeight="120px"
              src={image}
              width={variant !== VariantType.BLOCK ? 'auto' : '100%'}
            />
          </Box>
        </Visible>
      )}
      <Box
        boxSizing="border-box"
        display={variant !== VariantType.BLOCK ? 'flex' : 'block'}
        flexDirection={variant !== VariantType.BLOCK ? 'column' : undefined}
        tag="span"
        width={variant !== VariantType.BLOCK ? '50%' : '100%'}
        padding={
          variant === VariantType.VERTICAL ? '40px 16px 24px' : '12px 16px'
        }
      >
        {title && (
          <Text
            color="inherit"
            display="block"
            fontSize={variant === VariantType.VERTICAL ? 12 : 11}
            fontWeight="normal"
            lineHeight={variant === VariantType.VERTICAL ? 16 : 14}
            marginBottom={variant === VariantType.VERTICAL ? '8px' : '4px'}
            tag="strong"
            textTransform="uppercase"
          >
            {title}
          </Text>
        )}
        {description && (
          <Text
            color="inherit"
            display="block"
            flex={variant !== VariantType.BLOCK ? '1' : undefined}
            fontSize={variant === VariantType.VERTICAL ? 16 : 14}
            lineHeight={variant === VariantType.VERTICAL ? 20 : 18}
            marginBottom={variant === VariantType.BLOCK ? '16px' : undefined}
            tag="span"
          >
            {description}
          </Text>
        )}
        {actionText && (
          <Box
            alignItems="center"
            className="product-info-banner-action"
            display="flex"
            tag="span"
          >
            <Text
              color={actionColor || 'inherit'}
              fontSize={14}
              lineHeight={18}
              tag="span"
            >
              {actionText}
            </Text>
            <IconArrowSecondary size={12} color={actionColor || 'inherit'} />
          </Box>
        )}
        {children}
      </Box>
    </>
  );
};

export default ProductInfoBannerContent;
