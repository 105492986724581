import { FC } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import messages from 'modules/Ui/Pagination/messages';

import Box from '../Box';
import { IconArrow } from '../Icons';
import ButtonNextPrev from '../Pagination/ButtonNextPrev';
import Text from '../Text';
import carrouselMessages from './messages';
import { CarouselProps } from './model';

interface CarouselHeaderProps extends CarouselProps {}

export const CarouselHeader: FC<CarouselHeaderProps> = ({
  id,
  pages,
  selectedPage,
  setSelectedPage,
  title,
}) => {
  const { t } = useTranslations();
  return (
    <Box
      alignItems="center"
      display="grid"
      gridTemplateColumns="repeat(2, auto)"
      marginBottom="8px"
    >
      <Text
        fontSize={12}
        fontWeight="normal"
        id="carrousel-title"
        lineHeight={14}
        tag="h3"
        textTransform="uppercase"
      >
        <Box tag="span" accessibleHidden>
          {t(carrouselMessages.title)}
        </Box>
        {title}
      </Text>
      {pages > 1 && (
        <Box
          display="grid"
          gap="8px"
          gridTemplateColumns="repeat(2, auto)"
          justifyContent="flex-end"
          tag="ul"
        >
          <Box tag="li">
            <ButtonNextPrev
              accessibleText={t(messages.prevPage)}
              id={`prev-${id}-carousel-page`}
              isDisabled={selectedPage === 1}
              onClick={() => setSelectedPage(selectedPage - 1)}
            >
              <IconArrow transform="rotate(90deg)" />
            </ButtonNextPrev>
          </Box>
          <Box tag="li">
            <ButtonNextPrev
              accessibleText={t(messages.nextPage)}
              id={`next-${id}-carousel-page`}
              isDisabled={selectedPage === pages}
              onClick={() => setSelectedPage(selectedPage + 1)}
            >
              <IconArrow transform="rotate(-90deg)" />
            </ButtonNextPrev>
          </Box>
        </Box>
      )}
    </Box>
  );
};
