import { getConfig } from 'modules/App/config';
import buildBaseIdsQuery from 'modules/App/queries/buildBaseIdsQuery';
import {
  DASHBOARD_QUERY,
  DOCUMENTS_QUERY,
  DOCUMENTS_RECURRENTS_QUERY,
  DOCUMENT_TRACKING_QUERY,
  CONTACTS_QUERY,
} from 'modules/App/queries/queries';
import buildFiltersQuery, {
  buildDocumentTypeStatusQuery,
} from 'modules/Documents/helpers/buildFiltersQuery';
import {
  DocumentFilters,
  DocumentType,
  ExpenseCategories,
} from 'modules/Documents/models/document';
import request from 'utils/request';

interface Params {
  ids: string[];
  excludeIds: boolean;
  filters?: DocumentFilters;
  documentType?: DocumentType;
  category?: ExpenseCategories;
  tags?: string[];
}

interface Response {
  data: {
    count: number;
  };
}

const updateDocuments = async ({
  excludeIds,
  ids,
  filters,
  documentType,
  category,
  tags,
}: Params) => {
  const endpoint = (await getConfig()).apiDocumentsService;

  let query = buildBaseIdsQuery(ids, excludeIds);

  if (excludeIds && filters) {
    query += buildFiltersQuery(filters);
  }

  query = buildDocumentTypeStatusQuery(query, {
    excludeTestInvoices: true,
    documentType,
  });

  const response = await request<Response>(`${endpoint}?${query}`, {
    method: 'PUT',
    data: { category, tags },
  });

  if (response.status < 200 || response.status >= 400) {
    throw new Error('Error actualizando documentos');
  }

  return response.data;
};

export const invalidateQueries = [
  DOCUMENTS_QUERY,
  DOCUMENTS_RECURRENTS_QUERY,
  DASHBOARD_QUERY,
  DOCUMENT_TRACKING_QUERY,
  CONTACTS_QUERY,
];

export default updateDocuments;
