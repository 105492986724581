import Box, { BoxProps } from '../Box/Box';

type IconInfoProps = {
  size?: number;
  color?: string;
  rotate?: number;
} & BoxProps;

const IconInfo = (props: IconInfoProps) => {
  const { size = 24, color, rotate = 0 } = props;
  return (
    // Safari has some limitation rendering SVG so we are taking a different approach to this icon.
    <Box
      alignItems="center"
      aria-hidden="true"
      borderColor={color}
      borderRadius="50%"
      borderStyle="solid"
      borderWidth="1px"
      boxSizing="border-box"
      display="flex"
      fontSize={`${size - 4}px`}
      height={size}
      justifyContent="center"
      lineHeight={size}
      tag="span"
      transform={`rotate(${rotate}deg)`}
      textTransform="lowercase"
      width={size}
      {...{ color, ...props }}
    >
      i
    </Box>
  );
};

export default IconInfo;
