import { Entity } from 'modules/App/models';

import { UserNotificationPaylodType } from './UserNotificationPaylodType';

export enum ActionType {
  NAVIGATE_TO_BUSINESS = 'NAVIGATE_TO_BUSINESS',
  NAVIGATE_TO_BOOKKEEPER_INFO = 'NAVIGATE_TO_BOOKKEEPER_INFO',
  NAVIGATE_TO_INVOICE = 'NAVIGATE_TO_INVOICE',
  NAVIGATE_TO_INVOICES = 'NAVIGATE_TO_INVOICES',
  NAVIGATE_TO_INVOICE_SETTINGS = 'NAVIGATE_TO_INVOICE_SETTINGS',
  NAVIGATE_TO_BUSINESS_PROFILE = 'NAVIGATE_TO_BUSINESS_PROFILE',
  NAVIGATE_TO_BUSINESS_PROFILE_IN_BOOKKEEPER = 'NAVIGATE_TO_BUSINESS_PROFILE_IN_BOOKKEEPER',
  NAVIGATE_TO_CREATE_INVOICE = 'NAVIGATE_TO_CREATE_INVOICE',
  NAVIGATE_TO_CREATE_RECURRENT_INVOICE = 'NAVIGATE_TO_CREATE_RECURRENT_INVOICE',
  NAVIGATE_TO_CREATE_QUOTE = 'NAVIGATE_TO_CREATE_QUOTE',
  NAVIGATE_TO_CREATE_PROFORMA = 'NAVIGATE_TO_CREATE_PROFORMA',
  NAVIGATE_TO_CREATE_DELIVERY_NOTE = 'NAVIGATE_TO_CREATE_DELIVERY_NOTE',
  NAVIGATE_TO_CREATE_RECEIPT = 'NAVIGATE_TO_CREATE_RECEIPT',
  NAVIGATE_TO_CREATE_RECURRENT_EXPENSE = 'NAVIGATE_TO_CREATE_RECURRENT_EXPENSE',
  NAVIGATE_TO_UPLOAD_EXPENSE = 'NAVIGATE_TO_UPLOAD_EXPENSE',
  NAVIGATE_TO_UPLOAD_DOCUMENT = 'NAVIGATE_TO_UPLOAD_DOCUMENT',
  ACCEPT_INVITATION = 'ACCEPT_INVITATION',
  DOWNLOAD_ZIP = 'DOWNLOAD_ZIP',
  EMPTY = 'EMPTY',
}

export type CtaKey = {
  cta: string;
  isBodyAction?: boolean;
} & (
  | {
      action: ActionType.ACCEPT_INVITATION;
      token: string;
    }
  | {
      action: ActionType.NAVIGATE_TO_BUSINESS;
      businessId: string;
    }
  | {
      action: ActionType.NAVIGATE_TO_BOOKKEEPER_INFO;
      businessId: string;
    }
  | {
      action: ActionType.NAVIGATE_TO_INVOICE;
      documentId: string;
    }
  | {
      action: ActionType.NAVIGATE_TO_INVOICES;
      documentIds: string[];
    }
  | {
      action: ActionType.NAVIGATE_TO_INVOICE_SETTINGS;
      businessId: string;
    }
  | {
      action: ActionType.NAVIGATE_TO_BUSINESS_PROFILE;
      businessId: string;
    }
  | {
      action: ActionType.NAVIGATE_TO_BUSINESS_PROFILE_IN_BOOKKEEPER;
      businessId: string;
    }
  | {
      action: ActionType.DOWNLOAD_ZIP;
      businessId: string;
      zipId: string;
    }
  | {
      action: ActionType.NAVIGATE_TO_CREATE_INVOICE;
    }
  | {
      action: ActionType.NAVIGATE_TO_CREATE_RECURRENT_INVOICE;
    }
  | {
      action: ActionType.NAVIGATE_TO_CREATE_QUOTE;
    }
  | {
      action: ActionType.NAVIGATE_TO_CREATE_PROFORMA;
    }
  | {
      action: ActionType.NAVIGATE_TO_CREATE_DELIVERY_NOTE;
    }
  | {
      action: ActionType.NAVIGATE_TO_CREATE_RECEIPT;
    }
  | {
      action: ActionType.NAVIGATE_TO_CREATE_RECURRENT_EXPENSE;
    }
  | {
      action: ActionType.NAVIGATE_TO_UPLOAD_EXPENSE;
    }
  | {
      action: ActionType.NAVIGATE_TO_UPLOAD_DOCUMENT;
    }
  | {
      action: ActionType.EMPTY;
    }
);

export interface UserNotification extends Entity {
  ctaKeys: CtaKey[];
  type: UserNotificationPaylodType['type'];
  bodyKey: string;
  updatedAt: Date;
  createdAt: Date;
  payload: UserNotificationPaylodType;
  read: boolean;
  owner?: string;
  user?: string;
  avatarName?: string;
}
