import { AppConfig } from 'modules/App/config/models';
import featureFlags from 'modules/FeatureFlags';

export const getApiDomain = async (
  appConfig: AppConfig,
  isApi = false
): Promise<string> => {
  const isNotProd =
    Boolean(appConfig.environment) && appConfig.environment !== 'production';

  const apiDomainFF = (
    (featureFlags.getFlag('apiDomain') as string) || ''
  ).trim();

  let { apiDomain } = appConfig;
  let { apiPantumacaDomain } = appConfig;

  const hasCustomFF = isNotProd && apiDomainFF && apiDomainFF !== '';

  if (hasCustomFF) {
    apiDomain = apiDomainFF.replace(/\/$/, '');
    apiPantumacaDomain = `${apiDomain.replace('tortilla', 'pantumaca')}/v1`;
  }
  return isApi ? apiPantumacaDomain : apiDomain;
};
