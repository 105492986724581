import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useDebounce } from 'react-use';

import { isEmpty } from 'lodash';

import { AnalyticsEvent } from 'modules/Analytics';
import analyticsService from 'modules/Analytics/services/analyticsService';
import { DOCUMENTS_QUERY } from 'modules/App/queries/queries';
import { useDeletedContactMiddleware } from 'modules/Contacts/hooks/useDeletedContactMiddleware';
import useDocumentCreatePayment from 'modules/Documents/Detail/hooks/useDocumentCreatePayment';
import useDownloadDocumentFile from 'modules/Documents/hooks/useDownloadDocumentFile';
import {
  DocumentFilters,
  DocumentSort,
  DocumentStatus,
  DocumentTable,
  DocumentType,
} from 'modules/Documents/models/document';
import { ReceiptStatus } from 'modules/Documents/models/receiptStatus';
import { showModal } from 'modules/Modals/actions';
import { ModalTypes } from 'modules/Modals/constants';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import paymentMessages from 'modules/Payments/hooks/messages';
import { RemittanceType } from 'modules/Remittances/types';
import {
  selectHasUnlimitedPlan,
  selectIsMySubsciptionTrial,
} from 'modules/Subscriptions/detail/selectors';
import { DataTableSortOrder } from 'modules/Ui/DataTable/DataTable.models';
import useDataTablePagination from 'modules/Ui/DataTable/useDataTablePagination';
import { ROUTES } from 'pages/documents/routesMap';
import { roundNumber } from 'utils/MathOperations';
import { useBlockingMutation } from 'utils/useBlockingMutation';

import { notificationsMessages } from '../messages';
import downloadExcelService from '../services/downloadExcel';
import downloadZipService from '../services/downloadZipService';
import fetchDocuments from '../services/fetchDocuments';

export default function useDocumentListDataTable({
  status,
  isExpensesList,
  canVoidDocument,
  queryParams,
  searchTerm,
  updateQueryParams,
  extraQuery,
  excludeTestInvoices,
  initialSortBy,
}: {
  status?: DocumentStatus;
  isExpensesList?: boolean;
  canVoidDocument?: boolean;
  queryParams?: DocumentFilters;
  searchTerm: string;
  updateQueryParams: (event: DocumentFilters) => void;
  extraQuery?: string;
  excludeTestInvoices?: boolean;
  initialSortBy?: DocumentSort;
}) {
  const notifications = useNotifications();
  const dispatch = useDispatch();
  const history = useHistory();
  const { deletedContactMiddleware } = useDeletedContactMiddleware();
  const hasUnlimitedPlan = useSelector(selectHasUnlimitedPlan);
  const isTrial = useSelector(selectIsMySubsciptionTrial);
  const hasUnlimitedAccess = hasUnlimitedPlan || isTrial;

  const isContactList = history.location.pathname.includes('contacts');

  const { pagination, resetPagination, onPageSizeChange, onPageChange } =
    useDataTablePagination();

  const fallbackSortBy =
    status === DocumentStatus.DRAFT ? 'updatedAt' : 'issuedDateTime';
  const [sortBy, setSortBy] = useState<DocumentSort | undefined>({
    field: initialSortBy?.field ?? fallbackSortBy,
    order: initialSortBy?.order ?? DataTableSortOrder.DESC,
  });

  const initialFilters = !isEmpty(queryParams)
    ? queryParams
    : {
        searchTerm,
        issuedDateFrom: '',
        issuedDateTo: '',
      };

  const [filters, setFilters] = useState<DocumentFilters>(initialFilters ?? {});
  const [debouncedFilters, setDebouncedFilters] =
    useState<DocumentFilters>(filters);
  useDebounce(() => setDebouncedFilters(filters), 500, [filters]);

  const documentType = isExpensesList
    ? DocumentType.EXPENSE
    : DocumentType.INVOICE;

  const fields = [
    'id',
    'identifier',
    'code',
    'serialCode',
    'statusInfo',
    'lastSentStatus.status',
    'lastSentStatus.date',
    'issuedDate',
    'dueDate',
    'currency',
    'status',
    'digitalDocument.status',
    'contact',
    'counterparty',
    'totals',
    'documentType',
    'hasFile',
    'totalAmountFromPayments',
    'category',
    'correctiveData',
    'correctedDocuments',
    'paidStatus',
    'correctiveDocuments',
  ];
  const response = useQuery(
    [
      DOCUMENTS_QUERY,
      {
        ...pagination,
        filters: debouncedFilters,
        sortBy,
        status,
        isExpensesList,
        canVoidDocument,
        fields,
        extraQuery,
      },
    ],
    () =>
      fetchDocuments({
        ...pagination,
        filters: debouncedFilters,
        sortBy,
        status,
        documentType,
        canVoidDocument,
        fields,
        extraQuery,
        excludeTestInvoices,
      }),
    {
      onError: () => {
        notifications.error(notificationsMessages.error.id);
      },
    }
  );

  const { mutate } = useBlockingMutation(downloadZipService, {
    onSuccess: ({ taskId }) => {
      dispatch(
        showModal({
          type: ModalTypes.DOWNLOAD_DOCUMENTS_ZIP,
          documentType,
          taskId,
        })
      );
    },
    onError: () => {
      notifications.error(notificationsMessages.downloadZipError.id);
    },
  });

  const { mutate: onDownloadExcel } = useBlockingMutation(
    downloadExcelService,
    {
      onError: () => {
        notifications.error(notificationsMessages.downloadExcelError.id);
      },
    }
  );

  const [handleCreatePayment] = useDocumentCreatePayment({});

  useEffect(() => {
    resetPagination();
    updateQueryParams(filters);
  }, [filters]);

  useEffect(() => {
    resetPagination();
    setFilters({ ...filters, searchTerm });
  }, [searchTerm]);

  const showZeroState =
    !response.data?.items.length &&
    !response.isFetching &&
    Object.keys(filters).every((key) => !filters[key as keyof DocumentFilters]);

  function deleteDocuments(
    selecteds: DocumentTable[],
    inverted: boolean,
    resetSelection: () => void
  ) {
    const callback = () => {
      resetSelection();
      resetPagination();
    };
    const { documentType: type } = inverted
      ? { documentType }
      : selecteds.map((c) => c)[0];
    dispatch(
      showModal({
        type: ModalTypes.DELETE_DOCUMENTS,
        ids: selecteds.map((c) => c.id),
        inverted,
        filters,
        status,
        documentType: type,
        callback,
        totalCount: response.data?.count,
      })
    );
  }

  async function rectificateDocument(
    selecteds: DocumentTable[],
    shouldShowModal: boolean
  ) {
    const document = selecteds[0];
    await deletedContactMiddleware({
      nextStep: () => {
        const { id, receiptStatus } = selecteds[0];
        const isReceipt = receiptStatus === ReceiptStatus.IS_RECEIPT;
        if (shouldShowModal) {
          dispatch(
            showModal({
              type: ModalTypes.RECTIFY_DOCUMENT,
              path: `${ROUTES.DOCUMENTS_ISSUED}/${id}`,
              isReceipt,
            })
          );
          return;
        }
        isReceipt
          ? history.push(`/documents/issued/${id}/tickets-rectify-substitute`)
          : history.push(`/documents/issued/${id}/rectify-substitute`);
      },
      contactId: document.contact?.id,
      messageNotification: notificationsMessages.noContact.id,
    });
  }

  async function emitDraft(
    selecteds: DocumentTable[],
    _inverted: boolean,
    resetSelection: () => void
  ) {
    const document = selecteds[0];
    if (document.documentType === DocumentType.CORRECTIVE) {
      await rectificateDocument(selecteds, false);
      return;
    }
    editDocument(selecteds, _inverted, resetSelection);
  }

  function sortOutDocument(
    selecteds: DocumentTable[],
    inverted: boolean,
    resetSelection: () => void
  ) {
    dispatch(
      showModal({
        type: ModalTypes.SORT_OUT_EXPENSES_DOCUMENTS,
        ids: selecteds.map(({ id }) => id),
        filters,
        excludeIds: !!inverted,
        documents: response.data?.items,
      })
    );
    resetSelection();
  }

  const downloadDocumentFile = useDownloadDocumentFile();

  async function downloadDocument(
    selecteds: DocumentTable[],
    _inverted: boolean,
    resetSelection: () => void
  ) {
    // TODO: error handling
    await downloadDocumentFile(selecteds[0]);
    resetSelection();
  }

  async function downloadExcel(
    selecteds: DocumentTable[],
    inverted: boolean,
    resetSelection: () => void
  ) {
    await onDownloadExcel({
      ids: selecteds.map(({ id }) => id),
      excludeIds: inverted,
      status,
      documentType,
      filters,
      sortBy,
      canVoidDocument,
    });
    resetSelection();
  }

  function downloadZip(
    selecteds: DocumentTable[],
    inverted: boolean,
    resetSelection: () => void
  ) {
    mutate({
      ids: selecteds.map((c) => c.id),
      excludeIds: Boolean(inverted),
      status,
      documentType,
      filters,
      canVoidDocument,
    });
    resetSelection();
  }

  function editDocument(
    selecteds: DocumentTable[],
    _inverted: boolean,
    resetSelection: () => void
  ) {
    const {
      id,
      hasFile,
      receiptStatus,
      status: documentStatus,
      documentType: type,
      correctiveData,
    } = selecteds[0];
    let url;
    if (isExpensesList) {
      url = `/${id}/edit-upload`;
    } else {
      url = `/issued/${id}/${hasFile ? 'edit-upload' : 'edit'}`;
    }

    if (receiptStatus === ReceiptStatus.IS_RECEIPT) {
      url = url.replace('/issued', '/tickets');
    }
    if (
      documentStatus === DocumentStatus.DRAFT &&
      type === DocumentType.CORRECTIVE
    ) {
      url = correctiveData?.isReplacementFromReceipt
        ? `${url.replace('/issued', '/tickets').replace('/edit', '/replace')}`
        : `/issued/${id}/rectify-substitute`;
    }

    history.push(`${history.location.pathname}${url}`);
    resetSelection();
  }

  function sendDocument(
    selecteds: DocumentTable[],
    _inverted: boolean,
    resetSelection: () => void
  ) {
    const { id } = selecteds[0];
    history.push(`/documents/${DocumentType.INVOICE}/${id}/send`);
    resetSelection();
  }

  function copyDocument(
    selecteds: DocumentTable[],
    _inverted: boolean,
    resetSelection: () => void
  ) {
    const document = selecteds[0];
    const { hasFile, documentType: docType } = document;
    const pathname = isContactList ? '/documents' : history.location.pathname;
    const isExpense = docType === DocumentType.EXPENSE;
    const isUploadedDocument = isExpense || !!hasFile;

    if (!isUploadedDocument) {
      const url = `/issued/${document.id}/copy`;
      history.push(`${pathname}${url}`);
      resetSelection();

      return;
    }

    deletedContactMiddleware({
      nextStep: () => {
        const url = isExpensesList
          ? `/${document.id}/copy-upload`
          : `/issued/${document.id}/copy-upload`;
        history.push(`${pathname}${url}`);
        resetSelection();
      },
      contactId: document.contact?.id,
      messageNotification: notificationsMessages.noContact.id,
    });
  }

  function replaceToInvoice(
    selecteds: DocumentTable[],
    _inverted: boolean,
    resetSelection: () => void
  ) {
    const { id } = selecteds[0];

    const url = `/tickets/${id}/replace/document-data`;
    history.push(`${history.location.pathname}${url}`);
    resetSelection();
  }

  async function createPayment(
    selecteds: DocumentTable[],
    inverted: boolean,
    resetSelection: () => void
  ) {
    const [documentData] = selecteds;

    if (selecteds.length > 100) {
      notifications.error(paymentMessages.limitExceeded.id);
      return;
    }

    await handleCreatePayment({
      documentData,
      onSuccess: resetSelection,
      multiple: selecteds.length > 1 || inverted,
      multipleDeleteParams: {
        ids: selecteds.map(({ id }) => id),
        status,
        documentTypes: isExpensesList
          ? [DocumentType.EXPENSE]
          : [DocumentType.INVOICE, DocumentType.CORRECTIVE],
      },
    });
  }

  async function createRemittance(
    selecteds: DocumentTable[],
    _inverted: boolean,
    _resetSelection: () => void
  ) {
    if (!hasUnlimitedAccess) {
      dispatch(
        showModal({
          type: ModalTypes.ADD_REMITTANCE_PLAN_ERROR_MODAL,
        })
      );
      return;
    }

    dispatch(
      showModal({
        type: ModalTypes.ADD_REMITTANCE_MODAL,
        preSelectedDocuments: selecteds,
        totalCount: selecteds.length,
        remittanceType: isExpensesList
          ? RemittanceType.CREDIT_TRANSFER
          : RemittanceType.DIRECT_DEBIT,
        totalAmount: roundNumber(
          selecteds.reduce(
            (accumulator, doc) =>
              accumulator + (doc.totals.total - doc.totalAmountFromPayments),
            0
          )
        ),
      })
    );

    analyticsService.track(
      isExpensesList
        ? AnalyticsEvent.REMITTANCE_FROM_EXPENSES
        : AnalyticsEvent.REMITTANCE_FROM_DOCUMENTS
    );
  }

  function voidSelectedDocument(
    selecteds: DocumentTable[],
    _inverted: boolean,
    resetSelection: () => void
  ) {
    dispatch(
      showModal({
        type: ModalTypes.VOID_DOCUMENT,
        document: selecteds[0],
      })
    );
    resetSelection();
  }

  return {
    ...response,
    pagination,
    sortBy,
    filters,
    showZeroState,
    setSortBy,
    onPageSizeChange,
    onPageChange,
    setFilters,
    deleteDocuments,
    downloadDocument,
    downloadExcel,
    downloadZip,
    editDocument,
    copyDocument,
    replaceToInvoice,
    sendDocument,
    sortOutDocument,
    createPayment,
    rectificateDocument,
    voidSelectedDocument,
    emitDraft,
    createRemittance,
  };
}
