import { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { layout, position } from 'styled-system';

import { selectCurrentBusiness } from 'modules/Business/Current/selectors';
import accessibleHidden from 'modules/Theme/mixins/accessibleHidden';
import fadein from 'modules/Theme/mixins/fadeIn';
import styled, { css } from 'modules/Theme/styled-components';
import { Box, Text, Hr } from 'modules/Ui';
import { BoxProps } from 'modules/Ui/Box/Box';
import { zIndex } from 'modules/Ui/zIndex';
import { selectUser } from 'modules/Users/selectors';
import { getFullName } from 'modules/Users/userUtils';

import UserRole from '../../../Components/UserRole';

interface ExpandableBoxProps extends BoxProps {
  children?: string | ReactNode;
  hide?: boolean;
  open?: boolean;
}

const contentVisible = css`
  box-shadow: 0 4px 10px 0 ${(props) => props.theme.colors.shadow};
  animation: ${fadein} 0.1s ease-in;
  background: ${(props) => props.theme.colors.gray0};
  display: block;
  position: absolute;
  z-index: ${zIndex.ARROWBOX};
`;

const contentHide = css`
  ${accessibleHidden}
`;

const Popup = styled(Box)<ExpandableBoxProps>`
  ${layout}
  ${position}
  ${({ hide }) => (hide ? contentHide : contentVisible)}
`;

const ExpandableBox = (props: ExpandableBoxProps) => {
  const { open, children, hide, ...rest } = props;
  const userData = useSelector(selectUser);
  const fullName = getFullName(userData);
  const { fiscalName: businessName } = useSelector(selectCurrentBusiness);
  return (
    <Popup
      {...{ open, hide, ...rest }}
      aria-labelledby="menu-button-expandable"
      borderRadius="8px"
      hide={!open}
      id="menu-expandable"
      maxWidth={open ? '280px' : undefined}
      overflow="hidden"
      right={open ? '0' : undefined}
      role="region"
      minWidth={open ? '236px' : undefined}
      width={open ? 'fit-content' : undefined}
    >
      <Box padding="12px 16px">
        <Text
          color="primary500"
          fontWeight="600"
          fontSize="16px"
          lineHeight="20px"
          marginBottom="4px"
          wordBreak="break-word"
        >
          {businessName}
        </Text>
        <Box display="flex" alignItems="center " justifyContent="space-between">
          {fullName && (
            <Text textSize="xs" marginRight="4px" hasEllipsis>
              {fullName}
            </Text>
          )}
          {userData.role && <UserRole />}
        </Box>
      </Box>
      <Hr />
      <Box tag="ul">{children}</Box>
    </Popup>
  );
};

export default ExpandableBox;
