import useTranslations from 'modules/I18n/hooks/useTranslations';
import DataTableZeroState from 'modules/Ui/DataTable/DataTableZeroState';

import { zeroStatePayments } from '../messages';

const PaymentZeroState = () => {
  const { t } = useTranslations();
  return (
    <DataTableZeroState
      img="/assets/zero-state-payments.svg"
      imgMarginBottom="8px"
      subtitle={t(zeroStatePayments.subtitle)}
      title={t(zeroStatePayments.title)}
    />
  );
};

export default PaymentZeroState;
