import { ReactNode, FC } from 'react';

import { useFeatureFlag } from 'modules/FeatureFlags';
import { Box, Text, PillBeta } from 'modules/Ui';
import { IconArrow } from 'modules/Ui/Icons';

import { ConditionalToLink } from './ConditionalToLink';

export interface LinkProps {
  active?: boolean;
  className?: string;
  onClick?: () => void;
  color?: string;
  icon?: string | ReactNode;
  onlyIcon?: boolean;
  isBookkeeper?: boolean;
  requiredFeatureFlag?: string;
  text?: string;
  to?: string;
  menuStyle?: 'light' | 'dark';
  ['data-testid']?: string;
  notification?: string | ReactNode;
  rel?: string;
  isBeta?: boolean;
  isLinkToSubmenu?: boolean;
}

const MenuLink: FC<LinkProps> = (props) => {
  const {
    active,
    className,
    onClick,
    color,
    icon,
    onlyIcon,
    requiredFeatureFlag,
    isLinkToSubmenu = false,
    text,
    to,
    menuStyle = 'dark',
    notification,
    isBookkeeper = false,
    isBeta,
    rel,
  } = props;
  const ffEnabled = !!useFeatureFlag(requiredFeatureFlag);

  if (!ffEnabled) return null;
  return (
    <Box
      role="menuitem"
      className={className}
      height={{ sm: '100%' }}
      overflow="hidden"
      tag="li"
      position={isBeta ? 'relative' : undefined}
      data-testid={props['data-testid']}
    >
      <ConditionalToLink
        $menuStyle={menuStyle}
        active={active}
        aria-label={onlyIcon ? `${text}` : undefined}
        color={color}
        isBoldUnderLine={isBookkeeper}
        isBookkeeper={isBookkeeper}
        onClick={onClick}
        onlyIcon={onlyIcon}
        rel={rel}
        text={text}
        to={to}
        isLinkToSubmenu={isLinkToSubmenu}
      >
        {icon}
        {!onlyIcon && (
          <Text
            data-title-for-width={text}
            fontWeight={active ? '600' : undefined}
            color="inherit"
            fontSize={15}
            lineHeight={16}
            tag="span"
            textAlign={isLinkToSubmenu ? 'left' : undefined}
          >
            {text}
          </Text>
        )}
        {isBeta && !onlyIcon ? <PillBeta /> : undefined}
        {notification}
        {isLinkToSubmenu && (
          <IconArrow transform="rotate(-90deg)" color="primary500" />
        )}
      </ConditionalToLink>
    </Box>
  );
};

export default MenuLink;
