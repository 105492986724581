import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { loginBusiness, setScopes } from 'modules/Auth/actions';
import authBusiness from 'modules/Auth/services/authBusiness';
import { getBusiness } from 'modules/Business/Current/actions';
import { selectCurrentBusiness } from 'modules/Business/Current/selectors';

export const useAuthBusiness = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { id: currentBusinessId } = useSelector(selectCurrentBusiness);

  return async (id?: string, callback?: () => void) => {
    const selectedBusiness = id || currentBusinessId;
    const auth = await authBusiness(selectedBusiness as string);
    dispatch(setScopes(auth.scopes));
    dispatch(loginBusiness.success());
    dispatch(getBusiness.request());
    callback && callback();
    queryClient.resetQueries();
  };
};
