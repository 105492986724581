import { ReactNode, FC } from 'react';

import { layout, flexbox, FlexboxProps, LayoutProps } from 'styled-system';

import styled from 'modules/Theme/styled-components';

import { Button } from '../Html';

interface ButtonNextPrevProps extends FlexboxProps, LayoutProps {
  accessibleText: string;
  children: string | ReactNode;
  id?: string;
  isDisabled?: boolean;
  onClick: () => void;
}

const StyledButton = styled(Button)`
  ${flexbox}
  ${layout}

  @media (hover: hover) {
    &:hover {
      color: ${(props) => props.theme.colors.brand500};
    }
  }

  &:disabled {
    color: ${(props) => props.theme.colors.gray800};

    @media (hover: hover) {
      &:hover {
        cursor: text;
        svg {
          color: ${(props) => props.theme.colors.gray800};
        }
      }
    }

    &:focus {
      svg {
        color: ${(props) => props.theme.colors.gray800};
      }
    }
  }
`;

const ButtonNextPrev: FC<ButtonNextPrevProps> = ({
  accessibleText,
  isDisabled,
  children,
  id,
  onClick,
  ...rest
}) => {
  return (
    <StyledButton
      aria-label={accessibleText}
      disabled={isDisabled}
      id={id}
      onClick={onClick}
      type="button"
      {...rest}
    >
      {children}
    </StyledButton>
  );
};

StyledButton.defaultProps = {
  alignItems: 'center',
  display: 'flex',
  color: 'brand400',
};

export default ButtonNextPrev;
