import { FC } from 'react';

import useTranslations, {
  FormatCurrencyFunction,
} from 'modules/I18n/hooks/useTranslations';
import useTheme from 'modules/Theme/hooks/useTheme';

import Box from '../../../Box';
import Button from '../../Button';

export type Item = {
  value: string;
  label: string;
  description?: string;
  aditionalInfo?: any;
};

export type ExpandableListItemRendererProps = {
  selectItem: (item: Item) => void;
  getItemProps: any; // type hell from downshift
  selected?: boolean;
  index: number;
  item: {
    value: string;
    label: string;
    description?: string;
    aditionalInfo?: any;
  };
  formatCurrency: FormatCurrencyFunction;
  theme: ReturnType<typeof useTheme>;
};
export interface InputExpandableProps {
  items: Item[];
  seeMoreOptionsLabel?: string;
  getItemProps: any;
  getMenuProps: any;
  onSeeMoreOptionsLabelClick?: () => void;
  isOpen?: boolean;
  selectedItem?: any;
  expandableListItemRenderer: FC<ExpandableListItemRendererProps>;
  selectItem: (item: Item) => void;
  showMoreOptions?: boolean;
}

const ListExpandable: FC<InputExpandableProps> = ({
  seeMoreOptionsLabel,
  getItemProps,
  getMenuProps,
  onSeeMoreOptionsLabelClick,
  isOpen,
  items,
  selectedItem,
  expandableListItemRenderer,
  selectItem,
  showMoreOptions,
}) => {
  const theme = useTheme();
  const { formatCurrency } = useTranslations();

  return (
    <Box
      backgroundColor="gray0"
      border={`1px solid ${theme.colors.primary200}`}
      borderRadius="0px 0px 4px 4px"
      boxShadow={`0 4px 10px  ${theme.colors.shadow}`}
      fadeIn
      display={isOpen ? 'block' : 'none'}
      position="absolute"
      tag="ul"
      top="64px"
      width="100%"
      zIndex="10"
      {...getMenuProps()}
    >
      {items.map((item, index) =>
        expandableListItemRenderer({
          getItemProps,
          index,
          item,
          selected: selectedItem === item,
          selectItem,
          theme,
          formatCurrency,
        })
      )}
      {showMoreOptions && (
        <Box tag="li" margin="12px 8px 18px" textAlign="center">
          <Button
            variant="link"
            backgroundColor="transparent"
            color={theme.colors.brand500}
            fontSize={14}
            lineHeight={18}
            onClick={onSeeMoreOptionsLabelClick}
          >
            {seeMoreOptionsLabel}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ListExpandable;
