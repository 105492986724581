import { CardCvcElement, CardCvcElementProps } from '@stripe/react-stripe-js';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import styled from 'modules/Theme/styled-components';
import { Box, Text } from 'modules/Ui';
import inputWrapperStyles from 'modules/Ui/Form/Commons/styles/inputWrapperStyles';

import messages from '../messages';
import stripeStyles from './stripeStyles';

interface StripeProps extends CardCvcElementProps {}

const CardCvcStyles = styled(CardCvcElement)`
  ${inputWrapperStyles}
  ${stripeStyles}
`;

const CvcStripe = (props: StripeProps) => {
  const { ...rest } = props;
  const { t } = useTranslations();
  return (
    <Box
      width={{ _: 'calc(50% - 6px)', md: 'calc(50% - 12px)' }}
      data-testid="cvc-iframe-wrap"
    >
      <Text
        color="gray800"
        display="block"
        marginBottom="2px"
        fontSize={14}
        lineHeight={16}
        data-testid="card-cvv-stripe"
      >
        {t(messages.cvv)}
        <Text
          color="error"
          fontSize={14}
          fontWeight="500"
          lineHeight="normal"
          marginLeft="4px"
          tag="span"
        >
          *
        </Text>
      </Text>
      <CardCvcStyles {...rest} />
    </Box>
  );
};

export default CvcStripe;
