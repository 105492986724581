import { getConfig } from 'modules/App/config';
import { UserPermissions } from 'modules/Auth/constants';
import { expireToken } from 'modules/Auth/request-auth-data';
import { Coupon } from 'modules/Subscriptions/Create/types';
import request from 'utils/request';

export type ScopeTypes =
  | UserPermissions.OCR_SCANS
  | UserPermissions.CONTACTS_CREATE
  | UserPermissions.USERS_CREATE
  | UserPermissions.RECURRENT_CREATE
  | UserPermissions.PRODUCTS_CREATE
  | UserPermissions.REMITTANCES_CREATE
  | UserPermissions.EXPENSES_RECURRENT_CREATE;

export interface ChangePlanResponse {
  isAllowed: boolean;
  isDowngrade: boolean;
  status: string;
  client_secret: string;
  payment_method: string;
  reason?:
    | 'wrong-periodicity'
    | 'same-plan'
    | 'max-plan-changes-reached'
    | 'wrong-scope'
    | 'warning-scope';
  scopes?: [ScopeTypes];
  warningScopes?: [ScopeTypes];
}

export const changePlan = async ({
  productId,
  checkIsAllowed = true,
  coupon,
}: {
  productId: string;
  checkIsAllowed?: boolean;
  coupon?: Coupon;
}): Promise<ChangePlanResponse> => {
  const endpoint = (await getConfig()).apiSubscriptionsService;
  const fullEndpointUrl = `${endpoint}/change-plan?${
    checkIsAllowed ? 'isAllowed=true' : ''
  }`;
  const response = await request<{ data: ChangePlanResponse }>(
    fullEndpointUrl,
    {
      method: 'POST',
      data: { productId, coupon: coupon?.promotionCode },
    }
  );

  if (!checkIsAllowed) {
    expireToken();
  }

  return response.data.data;
};
