import { FC } from 'react';

import styled from 'styled-components';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import TemplateButton from 'modules/Settings/Theme/components/TemplateButton';
import { TemplateType } from 'modules/Settings/Theme/types';
import theme from 'modules/Theme';
import { circleHover } from 'modules/Theme/mixins';
import { Box, Carousel, CarouselSlides, Text } from 'modules/Ui';
import { IconArrow } from 'modules/Ui/Icons';
import ButtonNextPrev from 'modules/Ui/Pagination/ButtonNextPrev';

import messages from '../../messages';

const StyledCarousel = styled(Carousel)`
  .carousel__slides--item:not(:first-child) {
    margin-left: 16px;
  }

  button {
    @media (hover: hover) {
      &:hover {
        ${circleHover('30px', `${theme.colors.brand500}`)};
      }
    }
  }

`;

interface Props {
  templateType?: TemplateType;
}

const TemplateTypes: FC<Props> = ({ templateType = TemplateType.CLASSIC }) => {
  const { t } = useTranslations();

  const items = [
    {
      id: TemplateType.DEFAULT,
      template: '/assets/plantilla-predet.svg',
      text: t(messages.template01),
      value: TemplateType.DEFAULT,
    },
    {
      id: TemplateType.SIMPLE,
      template: '/assets/plantilla-sencilla.svg',
      text: t(messages.template02),
      value: TemplateType.SIMPLE,
    },
    {
      id: TemplateType.ZEBRA,
      template: '/assets/plantilla-cebreado.svg',
      text: t(messages.template03),
      value: TemplateType.ZEBRA,
    },
    {
      id: TemplateType.CLASSIC,
      template: '/assets/plantilla-clasico.svg',
      text: t(messages.template04),
      value: TemplateType.CLASSIC,
    },
  ];

  const initialSelectedPage =
    Math.round(
      (items.findIndex((item) => item.value === templateType) + 1) / 3
    ) + 1;

  const carrouselItems = () =>
    items.map((item) => (
      <TemplateButton
        key={item.id}
        id={item.id}
        template={item.template}
        text={item.text}
        value={item.value}
      />
    ));
  return (
    <>
      <Text
        color="gray800"
        fontSize={18}
        fontWeight="600"
        lineHeight={18}
        marginBottom="4px"
        tag="h3"
      >
        {t(messages.templateType)}
      </Text>
      <Box
        aria-labelledby="radio-group-template"
        marginBottom={{ _: '24px', sm: '32px', md: '24px', lg: '28px' }}
        role="radiogroup"
      >
        <Text
          fontSize={14}
          id="radio-group-template"
          lineHeight={20}
          marginBottom="24px"
          width="100%"
        >
          {t(messages.templateTypeText)}
        </Text>
        <StyledCarousel
          items={items}
          id="template-types"
          className="carousel__slides"
          initialItemsPerPage={3}
          display="grid"
          gridTemplateColumns="30px 1fr 30px"
          initialSelectedPage={initialSelectedPage}
          container={({
            id,
            itemsPerPage,
            setSelectedPage,
            pages,
            selectedPage,
          }) => (
            <>
              {selectedPage !== 1 ? (
                <ButtonNextPrev
                  accessibleText={t(messages.buttonPrev)}
                  alignSelf="center"
                  id={`prev-${id}-carousel-page`}
                  onClick={() => setSelectedPage(selectedPage - 1)}
                >
                  <IconArrow
                    color="brand500"
                    transform="rotate(90deg)"
                    size={30}
                  />
                </ButtonNextPrev>
              ) : (
                <Box />
              )}
              <CarouselSlides
                carouselGrid="repeat(3, 1fr)"
                id={id}
                items={carrouselItems()}
                itemsPerPage={itemsPerPage}
                pages={pages}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
                slidesPercentageMovement={33}
              />
              {selectedPage < pages ? (
                <ButtonNextPrev
                  accessibleText={t(messages.buttonNext)}
                  alignSelf="center"
                  id={`next-${id}-carousel-page`}
                  onClick={() => setSelectedPage(selectedPage + 1)}
                >
                  <IconArrow
                    color="brand500"
                    transform="rotate(-90deg)"
                    size={30}
                  />
                </ButtonNextPrev>
              ) : (
                <Box />
              )}
            </>
          )}
        />
      </Box>
    </>
  );
};

export default TemplateTypes;
