import { FC } from 'react';

import useTheme from 'modules/Theme/hooks/useTheme';

import Box from '../Box';
import { Button } from '../Form';
import Link from '../Link';

interface ActionMobileProps {
  id: string;
  onClick?: () => void;
  text: string;
  to?: string;
  variant?: 'secondary' | 'light' | 'stroke';
}

export const ActionMobile: FC<ActionMobileProps> = ({
  id,
  onClick,
  text,
  to,
  variant = 'secondary',
}) => {
  const theme = useTheme();
  return (
    <Box
      backgroundColor="gray0"
      borderTop={`1px solid ${theme.colors.gray200}`}
      bottom="0"
      padding="12px 16px"
      position="sticky"
    >
      {!!onClick && (
        <Button
          data-testid={id}
          onClick={onClick}
          variant={variant}
          width="100%"
        >
          {text}
        </Button>
      )}
      {to && !onClick && (
        <Link variant={variant} to={to} width="100%">
          {text}
        </Link>
      )}
    </Box>
  );
};
