import { FC } from 'react';

import useTranslations, {
  TranslationFunction,
} from 'modules/I18n/hooks/useTranslations';
import styled, { css } from 'modules/Theme/styled-components';
import { Box } from 'modules/Ui';
import { IconCheck } from 'modules/Ui/Icons';

import texts from '../../../messages';
import { PLANS } from '../../../models/constants';
import PlansInfoItem from './PlansInfoItem';

interface Props {
  productId: string;
}

function getProductIdItems(t: TranslationFunction, productId: string) {
  if (PLANS.BASIC.includes(productId)) {
    return [
      t(texts.basicItem1),
      t(texts.basicItem2),
      t(texts.basicItem3),
      t(texts.ocr),
      t(texts.basicItem4),
    ];
  }
  if (PLANS.PRO.includes(productId)) {
    return [
      t(texts.proItem1),
      t(texts.proItem2),
      t(texts.itemRecurrent),
      t(texts.expensesRecurrent),
      t(texts.proItem3),
      t(texts.ocrPro),
      t(texts.customerPanel),
      t(texts.proItem5),
      t(texts.proItem4),
    ];
  }

  return [
    t(texts.unlimitedItem1),
    t(texts.unlimitedItem2),
    t(texts.itemRecurrent),
    t(texts.expensesRecurrent),
    t(texts.unlimitedItem3),
    t(texts.ocrUnlimited),
    t(texts.customerPanel),
    t(texts.unlimitedItem6),
    t(texts.unlimitedItem4),
    t(texts.unlimitedItem7),
    t(texts.unlimitedItem5),
    t(texts.publicAPI),
  ];
}

const newFeaturesSeparator = css`
  border-top: 1px solid ${(props) => props.theme.colors.primary200};
  margin-top: 18px;
  padding-top: 20px;
`;

const Wrapper = styled(Box)`
  li {
    &:nth-last-child(3) {
      ${({ productId }) =>
        PLANS.UNLIMITED.includes(productId) && newFeaturesSeparator}
    }
    strong{
      color: ${(props) => props.theme.colors.brand500};
    }
  }
`;

const PlansInfo: FC<Props> = ({ productId }) => {
  const { t } = useTranslations();
  const items = getProductIdItems(t, productId);
  return (
    <Wrapper
      tag="ul"
      productId={productId}
      display="grid"
      rowGap="12px"
      padding={{
        _: '0 28px',
        sm: '0 16px',
        md: '0 40px',
        lg: '0 48px',
      }}
    >
      {items.map((item: string) => {
        return (
          <Box
            tag="li"
            display="grid"
            key={item}
            gap="8px"
            gridTemplateColumns="16px 1fr"
          >
            <IconCheck color="brand500" marginRight="8px" size={16} />
            <PlansInfoItem item={item} />
          </Box>
        );
      })}
    </Wrapper>
  );
};

export default PlansInfo;
