import { FC } from 'react';
import { useSelector } from 'react-redux';

import {
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  system,
} from 'styled-system';

import { selectIsMobile } from 'modules/App/store/selectors';
import theme from 'modules/Theme';
import { fadeInAnimation } from 'modules/Theme/mixins/fadeIn';
import styled from 'modules/Theme/styled-components';
import { IconAdd, IconInfo } from 'modules/Ui/Icons';

import WarningBase from './components/WarningBase';

export enum WarningType {
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

interface StyledWarningProps extends SpaceProps, LayoutProps, FlexboxProps {
  fadeIn?: boolean;
  gridColumnEnd?: {} | string;
  gridColumnStart?: {} | string;
  iconSize?: number;
  message?: string;
  messageFontSize?: number;
  messageLineHeight?: number;
  messageMargin?: string;
  type?: WarningType;
  useMarkdown?: boolean;
}

const colors: Record<WarningType, string> = {
  [WarningType.WARNING]: theme.colors.warningBackground,
  [WarningType.ERROR]: theme.colors.errorBackground,
};

const icons: Record<WarningType, (props: { size: number }) => JSX.Element> = {
  [WarningType.WARNING]: ({ size }) => (
    <IconInfo size={size} color="primary500" />
  ),
  [WarningType.ERROR]: ({ size }) => (
    <IconAdd transform="rotate(45deg)" size={size} color="error" />
  ),
};

const StyledWarning = styled(WarningBase)<StyledWarningProps>`
  ${({ fadeIn }) => fadeIn && fadeInAnimation}
  ${flexbox}
  ${layout}
  ${space}
  ${system({
    gridColumnStart: {
      property: 'gridColumnStart',
      transform: (value) => `${value}`,
    },
    gridColumnEnd: {
      property: 'gridColumnEnd',
      transform: (value) => `${value}`,
    },
  })}
`;

const Warning: FC<StyledWarningProps> = ({
  fadeIn,
  gridColumnEnd,
  gridColumnStart,
  iconSize,
  message,
  messageFontSize,
  messageLineHeight,
  messageMargin,
  type = WarningType.WARNING,
  useMarkdown = false,
  ...rest
}) => {
  const isMobile = useSelector(selectIsMobile);
  const size = isMobile ? 22 : 32;
  const icon = icons[type];
  const backgroundColor = colors[type];
  return (
    <StyledWarning
      backgroundColor={backgroundColor}
      fadeIn={fadeIn}
      gridColumnEnd={gridColumnEnd}
      gridColumnStart={gridColumnStart}
      icon={icon({ size: iconSize || size })}
      iconSize={iconSize || size}
      message={message || ''}
      messageFontSize={messageFontSize}
      messageLineHeight={messageLineHeight}
      messageMargin={messageMargin}
      useMarkdown={useMarkdown}
      {...rest}
    />
  );
};

StyledWarning.defaultProps = {
  marginBottom: '24px',
};

export default Warning;
