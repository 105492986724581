import { FC, ReactNode } from 'react';
import { Hidden, Visible } from 'react-grid-system';

import { ActionDesktop } from './ActionDesktop';
import { ActionMobile } from './ActionMobile';

interface MainActionProps {
  dropdown?: ReactNode;
  icon: ReactNode;
  id: string;
  onClick?: () => void;
  text: string;
  to?: string;
  variant?: 'secondary' | 'light' | 'stroke';
}

export const PageListMainAction: FC<MainActionProps> = ({
  dropdown,
  icon,
  id,
  onClick,
  text,
  to,
  variant = 'secondary',
}) => {
  return (
    <>
      <Visible xs>
        <ActionMobile
          id={id}
          onClick={onClick}
          text={text}
          to={to}
          variant={variant}
        />
      </Visible>
      <Hidden xs>
        {dropdown || (
          <ActionDesktop
            id={id}
            icon={icon}
            onClick={onClick}
            text={text}
            variant={variant}
            to={to}
          />
        )}
      </Hidden>
    </>
  );
};
