import { css } from 'modules/Theme/styled-components';

const expandable = css`
  summary {
    list-style: none;
    -webkit-appearance:none;
    outline: none;
    &::-webkit-details-marker {
      display:none;
    }

    @media (hover: hover) {
      &:hover {
        cursor: pointer;
      }
    }
  }

  summary + .expandable__content {
    content-visibility: hidden;
  }
  &[open] summary {
    .expandable__icon {
      transform: rotate(-180deg);
    }
    + .expandable__content {
      content-visibility: auto;
    }
  }
`;

export default expandable;
