import { FC, useEffect, useMemo, useState } from 'react';

import { TabQueryParamsState } from 'modules/Filters/types';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import {
  PaymentFilters,
  PaymentTable,
  PaymentTypes,
} from 'modules/Payments/models';
import breakpoints, { max, min } from 'modules/Theme/breakpoints';
import ellipsis from 'modules/Theme/mixins/ellipsis';
import styled from 'modules/Theme/styled-components';
import { Box, Spinner } from 'modules/Ui';
import DataTable from 'modules/Ui/DataTable/DataTable';
import DataTableFiltersWrapper from 'modules/Ui/DataTable/DataTableFiltersWrapper';

import { usePaymentListConfig, usePaymentListDataTable } from '../hooks';
import { filter, zeroResults } from '../messages';
import PaymentsFilterForm from './Filter/PaymentsFilterForm/PaymentFilterForm';
import TagList from './Filter/Tags/TagList';
import PaymentZeroState from './PaymentZeroState';

interface PaymentListProps {
  className: string;
  filtersActive: boolean;
  closeFilters: () => void;
  setFiltersDisabled: (status: boolean) => void;
  updateQueryParams: (event: PaymentFilters) => void;
  queryParamsState: TabQueryParamsState<PaymentFilters>;
  searchTerm: string;
}

const StyledPaymentsTable = styled(Box)`
    @media ${max(767)} {
      .data__table {
        &--account { grid-area: account; font-style: italic; font-size: ${(
          props
        ) => props.theme.fontSizes[14]};}
        &--operationDate { grid-area: operationDate; font-size: ${(props) =>
          props.theme.fontSizes[14]};}
        &--fiscalName { grid-area: fiscalName;}
        &--amount {grid-area: amount;}
        &--documents {grid-area: documents;}
        &--status {grid-area: status; display: flex;}
      }

      tbody tr {
        align-items: center;
        grid-template-columns: 1fr auto;
        grid-template-areas:
          "account operationDate"
          "fiscalName amount"
          "documents status";
      }
    }

    .data__table {
      @media ${min(breakpoints.sm)} {
        &--fiscalName {
        ${ellipsis}
        }
      }

      @media ${max(breakpoints.md)} {
        $--method {
          display: none;
        }
      }
    }

    @media ${min(768)} {
      tbody tr td  {
        @media (hover: hover) {
          &:hover {
            cursor: initial;
          }
        }
      }
    }

  `;

const PaymentList: FC<PaymentListProps> = ({
  className,
  filtersActive,
  closeFilters,
  queryParamsState,
  setFiltersDisabled,
  updateQueryParams,
  searchTerm,
}) => {
  const [showBigLoading, setShowBigLoading] = useState(true);
  const { t } = useTranslations();
  const {
    data,
    pagination,
    sortBy,
    isLoading,
    onPageSizeChange,
    onPageChange,
    setSortBy,
    setFilters,
    filters,
    showZeroState,
  } = usePaymentListDataTable({
    queryParams: queryParamsState[PaymentTypes.LIST],
    searchTerm,
    updateQueryParams,
  });
  const { bulkActions, columns } = usePaymentListConfig({
    filters,
    sortBy,
  });

  function handleSearchChanged(event: PaymentFilters) {
    handleTagsChanged({ ...filters, ...event, searchTerm });
    closeFilters();
  }

  function handleTagsChanged(event: PaymentFilters) {
    setFilters(event);
  }

  const items = useMemo((): PaymentTable[] => {
    return (
      data?.items.map((item: any) => ({
        ...item,
      })) || []
    );
  }, [data]);

  useEffect(() => {
    setFiltersDisabled(Boolean(showZeroState));
  }, [showZeroState, setFiltersDisabled]);

  useEffect(() => {
    setShowBigLoading(false);
  }, [data, setShowBigLoading, isLoading]);

  if (showZeroState) {
    setFiltersDisabled(true);
    return <PaymentZeroState />;
  }

  if (isLoading && showBigLoading) {
    return (
      <Box position="relative" height="440px">
        <Spinner />
      </Box>
    );
  }

  return (
    <>
      <DataTableFiltersWrapper
        modalTitle={t(filter.dialogDescription)}
        open={filtersActive}
        closeFilters={closeFilters}
        id="paymentsFilters"
      >
        <PaymentsFilterForm
          minAmount={filters.minAmount}
          maxAmount={filters.maxAmount}
          dateFrom={filters.dateFrom}
          dateTo={filters.dateTo}
          linked={filters.linked}
          paidStatus={filters.paidStatus}
          onSubmit={handleSearchChanged}
          onCancel={closeFilters}
        />
      </DataTableFiltersWrapper>
      <TagList
        {...{
          onChangeSearch: handleTagsChanged,
          filters,
        }}
      />

      <StyledPaymentsTable>
        <DataTable<PaymentTable, keyof PaymentTable>
          multiple={true}
          keyName="id"
          className={className}
          columns={columns}
          actions={bulkActions}
          data={items}
          page={pagination.page}
          pageSize={pagination.pageSize}
          totalCount={data?.count || 0}
          sortBy={sortBy}
          loading={isLoading}
          emptyDataTitle={t(zeroResults.title)}
          emptyDataSubTitle={t(zeroResults.subtitle)}
          filters={filters}
          onPageSizeChange={onPageSizeChange}
          onPageChange={onPageChange}
          onSortChange={(p) => {
            setSortBy(p);
          }}
          data-testid="payments-list"
        />
      </StyledPaymentsTable>
    </>
  );
};

export default PaymentList;
