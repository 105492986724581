import { useTenant } from 'modules/Subscriptions/hooks';
import { Tenants } from 'modules/Subscriptions/models';

function useIsTelefonica() {
  const tenant = useTenant();

  return tenant === Tenants.TELEFONICA;
}

export default useIsTelefonica;
