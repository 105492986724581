import { ReactNode } from 'react';
import { useDispatch } from 'react-redux';

import { Entity } from 'modules/App/models';

import { createNotification, removeNotification } from '../actions';
import { Notification, NotificationType } from '../models';

type DispatchNotification = (
  message: string | ReactNode,
  options?: Omit<Notification, 'id' | 'message' | 'type'> & Partial<Entity>
) => void;

export type UseNotifications = {
  delete: (id: string) => void;
  success: DispatchNotification;
  error: DispatchNotification;
  warning: DispatchNotification;
};

const useNotifications = (): UseNotifications => {
  const dispatch = useDispatch();
  const dispatchNotification =
    (type: NotificationType) =>
    (
      message: string | ReactNode,
      options?: Omit<Notification, 'id' | 'message' | 'type'> & Partial<Entity>
    ) => {
      if (typeof message !== 'string' && !options?.messageKey) {
        throw new Error(
          'notifications without a message key will break analytics, please provide message as messageId or an options.messageKey'
        );
      }
      dispatch(
        createNotification({
          type,
          message,
          ...options,
        })
      );
    };

  const deleteNotification = (id: string) => dispatch(removeNotification(id));
  return {
    delete: deleteNotification,
    success: dispatchNotification(NotificationType.SUCCESS),
    error: dispatchNotification(NotificationType.ERROR),
    warning: dispatchNotification(NotificationType.WARNING),
  };
};

export default useNotifications;
