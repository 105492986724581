import { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { underlineHover } from 'modules/Theme/mixins';
import styled from 'modules/Theme/styled-components';
import { Img, Text, Box } from 'modules/Ui';

import { selectIsMySubsciptionPaused } from '../detail/selectors';
import {
  subscriptionCancelledVodafone,
  subscriptionCancelledTelefonica,
  subscriptionCancelledDelSol,
  subscriptionCancelledText,
  subscriptionCancelledAplifisa,
} from '../messages';
import { SubscriptionType } from './types';

const Markdown = styled(ReactMarkdown)`
  a {
    ${underlineHover}
  }
`;

type Props = {
  partner: SubscriptionType;
};

const getMessages = (type: SubscriptionType) => {
  const messages = {
    vodafone: subscriptionCancelledVodafone,
    telefonica: subscriptionCancelledTelefonica,
    delsol: subscriptionCancelledDelSol,
    aplifisa: subscriptionCancelledAplifisa,
  };

  return messages[type];
};

const Cancelled: FC<Props> = ({ partner }) => {
  const { t } = useTranslations();
  const isSubscriptionPaused = useSelector(selectIsMySubsciptionPaused);
  const messages = getMessages(partner);
  return (
    <Box
      textAlign="center"
      maxWidth="856px"
      margin="0 auto"
      padding={{ _: '80px 16px 0', sm: '56px 0 0' }}
    >
      <Img
        margin="0 auto"
        ariaHidden
        height={168}
        width={264}
        marginBottom={{ _: '28px', sm: '12px' }}
        src="/assets/cancelled.svg"
      />
      <Text
        color="brand500"
        fontFamily="LyonMedium"
        fontSize={{ _: 32, sm: 56 }}
        fontWeight="500"
        lineHeight={{ _: '28px', sm: '70px' }}
        marginBottom={{ _: '18px', sm: '22px' }}
        tag="h2"
        textAlign="center"
      >
        {t(
          isSubscriptionPaused
            ? subscriptionCancelledText.pausedTitle
            : subscriptionCancelledText.title
        )}
      </Text>
      <Box
        color="gray800"
        fontSize={{ _: 14, sm: 18 }}
        lineHeight={{ _: 20, sm: 24 }}
      >
        {messages && <Markdown>{t(messages.description)}</Markdown>}
      </Box>
    </Box>
  );
};

export default Cancelled;
