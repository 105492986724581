import { useCallback, ReactNode, useState, useEffect } from 'react';

import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  border,
  BorderProps,
} from 'styled-system';

import styled from 'modules/Theme/styled-components';
import { Box, Button } from 'modules/Ui';

interface Props extends SpaceProps, BorderProps, FlexboxProps {
  content?: ReactNode;
  id?: string;
  initiallyExpanded?: boolean;
  onChange?(newExpandedState: boolean): void;
  title: string | ReactNode;
  titleAtTheEnd?: boolean;
  forceOpen?: boolean;
}

const StyledWrapper = styled(Box)`
  ${border}
  ${space}
  ${flexbox}
`;

const CustomExpandable: React.FC<Props> = ({
  content,
  id,
  initiallyExpanded = false,
  onChange,
  title,
  titleAtTheEnd,
  forceOpen,
  ...rest
}) => {
  const [expanded, setExpanded] = useState(initiallyExpanded);
  const toggleExpanded = useCallback(() => {
    if (forceOpen && expanded) {
      return;
    }
    setExpanded(!expanded);
    onChange && onChange(!expanded);
  }, [expanded, setExpanded, onChange, forceOpen]);
  useEffect(() => {
    if (forceOpen) {
      setExpanded(true);
      onChange && onChange(true);
    }
  }, [forceOpen]);
  return (
    <StyledWrapper
      display={titleAtTheEnd && 'flex'}
      flexDirection={titleAtTheEnd && 'column-reverse'}
      {...rest}
    >
      <Button
        aria-controls={`controls-${id}`}
        aria-expanded={expanded}
        className="expandable__button"
        data-testid={id}
        onClick={toggleExpanded}
        width="100%"
      >
        {title}
      </Button>
      <Box aria-labelledby={id} id={`controls-${id}`} role="region">
        <Box
          aria-hidden={!expanded}
          contentVisibility={expanded ? 'initial' : 'hidden'}
          display={!expanded && 'none'}
          fadeIn
        >
          {content}
        </Box>
      </Box>
    </StyledWrapper>
  );
};

export default CustomExpandable;
