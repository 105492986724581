import React, { ReactNode } from 'react';

import {
  color,
  ColorProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
} from 'styled-system';

import styled from 'modules/Theme/styled-components';
import Box from 'modules/Ui/Box';
import Link from 'modules/Ui/Link';

import ProductInfoBannerContent from './components/ProductInfoBannerContent';
import { VariantType } from './variant';

interface ProductInfoBannerProps extends ColorProps, SpaceProps, LayoutProps {
  actionColor?: string;
  actionText?: string;
  children?: ReactNode;
  description: string;
  id?: string;
  image: string;
  isImageVisibleInMobile?: boolean;
  onClick?: () => void;
  title?: string;
  to?: string;
  variant?: VariantType;
}

const getStyles = (variant: string) => {
  if (variant === VariantType.VERTICAL) {
    return {
      height: '256px',
    };
  }
  if (variant === VariantType.HORIZONTAL) {
    return {
      height: '120px',
    };
  }
  return {
    height: 'auto',
  };
};

export const StyledLink = styled(Link)``;

const ProductInfoWrapper = styled(Box)<ProductInfoBannerProps>`
  ${space}
  ${layout}
  ${color}
  ${StyledLink} {
    @media (hover: hover) {
      &:hover {
        cursor: pointer;
        .product-info-banner-action {
          ${({ actionColor }) => actionColor && `color: ${actionColor};`}
          text-decoration: underline;
        }
      }
    }
    &:focus {
      .product-info-banner-action {
        ${({ actionColor }) => actionColor && `color: ${actionColor};`}
        text-decoration: underline;
      }
    }
  }
`;

const ProductInfoBanner: React.FC<ProductInfoBannerProps> = (props) => {
  const {
    actionColor,
    actionText,
    children,
    description,
    id = 'product-info-banner',
    image,
    isImageVisibleInMobile,
    title,
    to = '',
    variant = VariantType.BLOCK,
    ...rest
  } = props;
  const { height } = getStyles(variant);
  return (
    <ProductInfoWrapper
      actionColor={actionColor}
      height={height}
      {...{ ...rest }}
    >
      {to ? (
        <StyledLink
          aria-label={`${title} ${description}`}
          color="inherit"
          data-testid={id}
          display={variant === VariantType.BLOCK ? 'block' : 'flex'}
          height="100%"
          to={to}
        >
          <ProductInfoBannerContent
            actionColor={actionColor}
            actionText={actionText}
            children={children}
            image={image}
            isImageVisibleInMobile={isImageVisibleInMobile}
            description={description}
            title={title}
            variant={variant}
          />
        </StyledLink>
      ) : (
        <Box
          color="inherit"
          display={variant === VariantType.BLOCK ? 'block' : 'flex'}
          height="100%"
          padding={variant === VariantType.BLOCK ? '0 0 16px' : undefined}
        >
          <ProductInfoBannerContent
            actionColor={actionColor}
            actionText={actionText}
            children={children}
            image={image}
            isImageVisibleInMobile={isImageVisibleInMobile}
            description={description}
            title={title}
            variant={variant}
          />
        </Box>
      )}
    </ProductInfoWrapper>
  );
};

ProductInfoWrapper.defaultProps = {
  backgroundColor: 'brand400',
  color: 'gray0',
};

export default ProductInfoBanner;
