import { FC, ReactNode } from 'react';

import { ButtonAction } from '../Form';

interface ActionDesktopProps {
  icon?: ReactNode;
  id: string;
  onClick?: () => void;
  text: string;
  to?: string;
  variant?: 'secondary' | 'light' | 'stroke';
}

export const ActionDesktop: FC<ActionDesktopProps> = ({
  icon,
  id,
  onClick,
  text,
  to,
  variant = 'secondary',
}) => {
  return (
    <ButtonAction
      data-testid={id}
      icon={icon}
      onClick={onClick}
      padding={{ sm: '16px 24px 16px 20px' }}
      role={onClick && 'button'}
      text={text}
      to={to || '#'}
      variant={variant}
    />
  );
};
