import { FC } from 'react';

import { space, SpaceProps } from 'styled-system';

import styled from 'modules/Theme/styled-components';

import Box from '../Box';
import { CarouselProps } from './model';

interface CarouselWrapperProps extends CarouselProps, SpaceProps {}

const StyledCarouseItem = styled(Box)``;
const StyledCarouselGrid = styled(Box)``;
const StyledCarouselSlides = styled(Box)`
  ${space}

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  ${StyledCarouseItem} {
    transition: transform 0.8s ease-in-out;
    will-change: transition;
  }
`;

export const CarouselSlides: FC<CarouselWrapperProps> = ({
  items,
  pages,
  itemsPerPage,
  selectedPage,
  carouselGrid,
  slidesPercentageMovement = 100,
  ...rest
}) => {
  return (
    <StyledCarouselSlides display="flex" overflowX="auto" {...rest}>
      {Array.from({ length: pages }, (_, i) => i + 1).map((page) => {
        const initialPosition = (page - 1) * itemsPerPage;
        return (
          <StyledCarouseItem
            aria-hidden={selectedPage !== page ? true : undefined}
            aria-label={`${page}/${pages}`}
            className="carousel__slides--item"
            flexShrink="0"
            key={`${page}-container`}
            role="group"
            transform={`translateX(${
              -slidesPercentageMovement * (selectedPage - 1)
            }%)`}
            width="100%"
          >
            <StyledCarouselGrid
              display="grid"
              gridTemplateColumns={carouselGrid}
              gap={{ _: '16px', lg: '20px' }}
              position="relative"
            >
              {items.slice(initialPosition, initialPosition + itemsPerPage)}
            </StyledCarouselGrid>
          </StyledCarouseItem>
        );
      })}
    </StyledCarouselSlides>
  );
};

StyledCarouselGrid.defaultProps = {
  gridTemplateColumns: { sm: 'repeat(3, 1fr)', lg: 'repeat(4, 1fr)' },
};
