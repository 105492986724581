import { ReactNode, FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import styled from 'styled-components';
import {
  gridTemplateColumns,
  GridTemplateColumnsProps,
  gridTemplateRows,
  GridTemplateRowsProps,
  layout,
  LayoutProps,
} from 'styled-system';

import { selectIsUltraWide } from 'modules/App/store/selectors';

import Box from '../Box';

export interface CarouselInputProps {
  className?: string;
  id: string;
  initialItemsPerPage?: number;
  items: ReactNode[];
  slidesPercentageMovement?: number;
  initialSelectedPage?: number;
}

export interface ContainerProps extends CarouselInputProps {
  itemsPerPage: number;
  pages: number;
  selectedPage: number;
  setItemsPerPage(prop: number): void;
  setSelectedPage(prop: number): void;
}

export interface CarouseContainerlWrapperProps
  extends CarouselInputProps,
    GridTemplateColumnsProps,
    GridTemplateRowsProps,
    LayoutProps {
  container(props: ContainerProps): void;
}

const StyledWrapper = styled(Box)`
  ${gridTemplateColumns}
  ${gridTemplateRows}
  ${layout}
`;

export const Carousel: FC<CarouseContainerlWrapperProps> = ({
  className,
  container,
  id,
  initialItemsPerPage = 4,
  items,
  slidesPercentageMovement,
  initialSelectedPage = 1,
  ...rest
}) => {
  const isWideScreen = useSelector(selectIsUltraWide);
  const [itemsPerPage, setItemsPerPage] = useState(initialItemsPerPage);
  const [selectedPage, setSelectedPage] = useState(initialSelectedPage);
  const pages = useMemo(() => {
    return Math.ceil((items.length || 0) / itemsPerPage);
  }, [itemsPerPage, items.length]);
  useEffect(() => {
    if (isWideScreen) {
      setSelectedPage(initialSelectedPage);
      setItemsPerPage(initialItemsPerPage);
    } else {
      setSelectedPage(initialSelectedPage);
      setItemsPerPage(3);
    }
  }, [isWideScreen]);

  return (
    <StyledWrapper
      className={className}
      id={id}
      pages={pages}
      selectedPage={selectedPage}
      {...rest}
    >
      {container({
        id,
        initialItemsPerPage,
        items,
        itemsPerPage,
        pages,
        selectedPage,
        setItemsPerPage,
        setSelectedPage,
        slidesPercentageMovement,
      })}
    </StyledWrapper>
  );
};
