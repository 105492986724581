import { useSelector } from 'react-redux';

import { UserPermissions } from 'modules/Auth/constants';
import useHasUserScope from 'modules/Auth/hooks/checkUsersPermissions';
import useIsBookkeeperUser, {
  useIsBookkeeperLoggedInClient,
} from 'modules/Bookkeeper/hooks/useIsBookkeeperUser';
import {
  selectCurrentBusiness,
  selectHasBookkeeperUsers,
} from 'modules/Business/Current/selectors';
import {
  selectHasExternalPlan,
  selectHasSubscription,
} from 'modules/Subscriptions/detail/selectors';
import {
  useTenantHasAccessToPlans,
  useTenant,
} from 'modules/Subscriptions/hooks';
import useIsTrustedBusinessCampaign from 'modules/Subscriptions/hooks/useIsTrustedBusinessCampaign';
import useIsReader from 'modules/Users/hooks/useIsReader';
import isNativeApp from 'utils/native/isNativeApp';
import useIsVodafone from 'utils/vodafone/hooks/is-vodafone';

export const useShouldRenderSection = () => {
  const tenantHasPlans = useTenantHasAccessToPlans();
  const { isDummy } = useSelector(selectCurrentBusiness);
  const { hasUserScope } = useHasUserScope();
  const hasSubscription = useSelector(selectHasSubscription);
  const isBookkeeper = useIsBookkeeperUser();
  const isUserAdmin = hasUserScope(UserPermissions.SUBSCRIPTION_ADMIN);
  const isBookkeeperLoggedInClient = useIsBookkeeperLoggedInClient();
  const hasBookkeeperUsers = useSelector(selectHasBookkeeperUsers);
  const isVodafone = useIsVodafone();
  const isSubscriptionExternal = useSelector(selectHasExternalPlan);
  const { isTrustedCampaign } = useIsTrustedBusinessCampaign();
  const isUserReader = useIsReader();
  const tenant = useTenant();

  const shouldRenderBusinessSection =
    !isBookkeeperLoggedInClient && !isBookkeeper;
  const shouldRenderSettingsSection = isUserAdmin && !isBookkeeper;
  const shouldRenderMyBookkeeperSection =
    !hasBookkeeperUsers && !isBookkeeper && !isUserReader;

  const shouldRenderPlansSection =
    !isNativeApp() &&
    isUserAdmin &&
    !isDummy &&
    !isBookkeeper &&
    !isVodafone &&
    !(isTrustedCampaign && isSubscriptionExternal) &&
    tenantHasPlans;

  const shouldRenderSubscriptionsSection =
    (!isNativeApp() &&
      hasSubscription &&
      !isBookkeeperLoggedInClient &&
      !isBookkeeper &&
      !isUserReader &&
      tenantHasPlans) ||
    !!tenant;

  return {
    shouldRenderMyBookkeeperSection,
    shouldRenderPlansSection,
    shouldRenderSettingsSection,
    shouldRenderBusinessSection,
    shouldRenderSubscriptionsSection,
  };
};
