import downloadFile from 'utils/downloadFile';

import downloadDocument from '../List/services/downloadDocument';
import { Document, DocumentStatus } from '../models/document';

const extensionMap: Record<string, string> = {
  'image/png': '.png',
  'image/jpeg': '.jpeg',
  'image/jpg': '.jpg',
  'application/pdf': '.pdf',
};

export default function useDownloadDocumentFile() {
  async function downloadDocumentFile(document: Document) {
    const { data, contentType } = await downloadDocument({
      id: document.id,
      hasFile: document.hasFile,
      headersOnly: false,
      downloadAttachedFile: false,
    });
    const file = new Blob([data], { type: contentType });

    return downloadFile(file, getDownloadFileName(document, contentType));
  }
  return downloadDocumentFile;
}

const getDownloadFileName = (
  document: Document,
  contentType: string,
  size = 70
): string => {
  const fiscalName = prepareString(document.contact?.fiscalName, size);

  let fileName = '';

  if (fiscalName.length > 0) {
    fileName = `${document.identifier ?? document.serialCode}_${fiscalName}`;
  } else {
    fileName = document.identifier ?? document.serialCode;
  }

  fileName += extensionMap[contentType];

  if (document.status === DocumentStatus.DRAFT) {
    fileName = `Borrador_${fileName}`;
  }

  return fileName;
};

export const prepareString = (
  str: string | undefined,
  size: number
): string => {
  if (!str || str.length === 0) return '';

  const lowerCased = str.toLowerCase();

  const mapObj = {
    à: 'a',
    è: 'e',
    ì: 'i',
    ò: 'o',
    ù: 'u',
  };

  const replaced = lowerCased
    .split('')
    .map((char) => mapObj[char as keyof typeof mapObj] || char)
    .join('');

  const cleaned = replaced.replace(/[^a-z0-9 áéíóúñ]/g, '');

  const camelCased = cleaned
    .split(' ')
    .filter((word) => word.length > 0)
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');

  return camelCased.substring(0, size);
};
