import { FC, createElement } from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentBusiness } from 'modules/Business/Current/selectors';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import Cancelled from 'modules/Subscriptions/cancelled/Cancelled';
import { SubscriptionType } from 'modules/Subscriptions/cancelled/types';
import useIsTrustedBusinessCampaign from 'modules/Subscriptions/hooks/useIsTrustedBusinessCampaign';
import { Plan } from 'modules/Subscriptions/models';
import useTheme from 'modules/Theme/hooks/useTheme';
import { Box, Text } from 'modules/Ui';
import useIsAplifisa from 'utils/aplifisa/hooks/is-aplifisa';
import useIsDelSol from 'utils/delsol/hooks/is-delsol';
import useIsTelefonica from 'utils/telefonica/hooks/is-telefonica';
import useIsVodafone from 'utils/vodafone/hooks/is-vodafone';

import { discountBanner } from '../../../messages';
import { PlansInfo } from '../PlansInfo';
import { SubscriptionPlans } from '../SubscriptionPlans';
import DiscountBanner from './DiscountBanner';
import Faqs from './Faqs';

interface Props {
  activePlan?: string;
  hasBookkeeper: boolean;
  hasCampaign: boolean;
  hasSubscription: boolean;
  onChangeBusinessClick(): void;
  onSelectPlan(data: Plan): void;
  plans: Plan[];
}

const getPartnerName = ({
  isVodafone,
  isTelefonica,
  isDelSol,
  isAplifisa,
}: {
  isVodafone?: boolean;
  isTelefonica?: boolean;
  isDelSol?: boolean;
  isAplifisa?: boolean;
}): SubscriptionType => {
  if (isVodafone) {
    return 'vodafone';
  }
  if (isTelefonica) {
    return 'telefonica';
  }
  if (isDelSol) {
    return 'delsol';
  }
  if (isAplifisa) {
    return 'aplifisa';
  }

  throw new Error('Invalid partner');
};

const Plans: FC<Props> = ({
  activePlan,
  hasBookkeeper,
  hasCampaign,
  hasSubscription,
  onChangeBusinessClick,
  onSelectPlan,
  plans,
}) => {
  const { t } = useTranslations();
  const theme = useTheme();
  const business = useSelector(selectCurrentBusiness);
  const { isTrustedCampaign } = useIsTrustedBusinessCampaign();
  const hasPartner = business.partner !== null;
  const showAddBookkeeperBanner =
    !hasBookkeeper &&
    !hasSubscription &&
    !hasCampaign &&
    !hasPartner &&
    !isTrustedCampaign;
  const showBookkeeperDiscountBanner =
    hasBookkeeper && hasCampaign && !isTrustedCampaign;
  const isVodafone = useIsVodafone();
  const isTelefonica = useIsTelefonica();
  const isDelSol = useIsDelSol();
  const isAplifisa = useIsAplifisa();

  const bold = (chunk: unknown[]) =>
    createElement(Text, {
      children: chunk,
      color: 'inherit',
      fontSize: 'inherit',
      marginRight: '4px',
      tag: 'strong',
    });

  const isPartnerSubscriptionCancelled =
    isVodafone || isTelefonica || isDelSol || isAplifisa;

  if (isPartnerSubscriptionCancelled) {
    return (
      <Cancelled
        partner={getPartnerName({
          isVodafone,
          isTelefonica,
          isDelSol,
          isAplifisa,
        })}
      />
    );
  }

  return (
    <Box backgroundColor="auxiliary" tag="section">
      <>
        {showBookkeeperDiscountBanner && (
          <DiscountBanner
            text={t(discountBanner.bookkeeperDiscountText, { bold })}
            pill={t(discountBanner.pill)}
          />
        )}
        {showAddBookkeeperBanner && (
          <DiscountBanner
            text={t(discountBanner.addBookkeeperText, { bold })}
            pill={t(discountBanner.pill)}
          />
        )}
        <SubscriptionPlans
          activePlan={activePlan}
          onChangeBusinessClick={onChangeBusinessClick}
          onSelectPlan={onSelectPlan}
          plans={plans}
        />
        <Box tag="section" marginBottom="24px">
          <PlansInfo plural={plans.length > 2} />
          <Box
            backgroundColor="gray0"
            border={{ sm: `1px solid ${theme.colors.primary100}` }}
            marginBottom={{ sm: '16px' }}
            padding={{ sm: '16px' }}
          >
            <Faqs />
          </Box>
        </Box>
      </>
    </Box>
  );
};

export default Plans;
